interface IParams {
  [key: string]: string | undefined;
}

const urlBuilder = (url: string, paramMap?: IParams, searchMap?: IParams): string => {
  const params = Object.entries(paramMap ?? {});
  const search = Object.entries(searchMap ?? {});
  let newUrl = url;

  if (params?.length > 0) {
    params?.forEach(([key, value]) => {
      if (value) {
        newUrl = newUrl.replace(`{${key}}`, value).replace(`:${key}`, value);
      }
    });
  }

  if (search?.length > 0) {
    const searchParams = new URLSearchParams();
    search?.forEach(([key, value]) => {
      if (value) {
        searchParams.append(key, value);
      }
    });
    newUrl += `?${searchParams.toString()}`;
  }

  return newUrl;
};

export default urlBuilder;
