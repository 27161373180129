import { useParams } from 'react-router-dom';

interface IUseApiaryParamsResponse {
  accountId: string;
  vendorId: string;
  customerId: string;
  challengeId: string;
  countryCode: string;
  categoryId: string;
  truckId: string;
  storeId: string;
  customerInvoiceNumber: string;
  orderNumber: string;
  bdrId: string;
  vendorName: string;
  visitId: string;
  taskId: string;
}

type ApiaryParamsKeys = keyof IUseApiaryParamsResponse;

export const useApiaryParams = (): IUseApiaryParamsResponse => {
  const params = useParams<ApiaryParamsKeys>() as IUseApiaryParamsResponse;
  return params;
};
