const formatText = (text?: string): string => {
  if (!text) return '';

  const lowerCaseText = text?.slice(1).toLowerCase();
  const upperChar = text?.[0].toUpperCase();
  const formattedString = upperChar.concat(lowerCaseText);
  return formattedString.replaceAll('_', ' ');
};

export default formatText;
