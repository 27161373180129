interface IStringObject {
  [key: string]: string | number | undefined;
}

const concatString = (text: string, object: IStringObject): string => {
  if (!object || !text) {
    return text;
  }

  let formatedText = text;
  const keys = Object.keys(object);

  keys.forEach((key) => {
    formatedText = formatedText.replaceAll(`{${key}}`, `${object?.[key]}`);
  });

  return formatedText;
};

export default concatString;
