import { EnvContext } from 'contexts/EnvProvider';
import { IEnvProps } from 'interfaces/IEnv';
import { useContext } from 'react';

export const useEnvContext = (): IEnvProps => {
  const {
    segmentKey,
    europeClusterCountries,
    multivendorCountries,
    enableDevTools,
    beesVendoCountries,
  } = useContext(EnvContext);

  return {
    segmentKey,
    europeClusterCountries,
    multivendorCountries,
    enableDevTools,
    beesVendoCountries,
  };
};
