/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface AccountCustomerDetailsPageViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the ID of the current customer
	 */
	customer_id: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface AccountCustomersPageViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface AccountDetailsTabSelected {
	/**
	 * Represents every tab in the Account Details Page
	 */
	account_details_tab: AccountDetailsTab
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface AccountDetailsViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface AccountFeatureSelected {
	/**
	 * Represents every feature available in the Account Tools Page
	 */
	account_feature: AccountFeature
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface AccountHomepageFeatureSelected {
	/**
	 * Represents every feature available in the Account Tools Page
	 */
	account_feature: AccountFeature
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface AccountHomepageViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface AccountIdError {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface AccountIdInformation {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface AccountNotFound {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the information on the account search
	 */
	id_number: string | null
	/**
	 * Represents the type of account search that is performed
	 */
	id_type: IdType
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface AccountPageViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * In order to find the account. Sample: ACCOUNT_ID, CUSTOMER_ACCOUNT
	 */
	find_account: FindAccount
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface AccountSearch {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface AddOrUpdateItemToCart {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * The array of items list. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	item_list: any[] | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface AlgoSellingViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface AssociatedAccountSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface AssociatedAccountsNotFound {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the ID of the current customer
	 */
	customer_id: string | null
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface BeesVendoPageViewed {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface CatalogNotFound {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface CategoriesListNotFound {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
}
export interface CategoriesPageViewed {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
}
export interface CategorySelect {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
}
export interface ChallengeSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * The challenge ID.
	 */
	challenge_id?: string
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface ChallengesNotFound {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface CheckoutDeliveryDateSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current truck cart
	 */
	truck_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface CheckoutPaymentMethodSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current truck cart
	 */
	truck_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface ClearSearch {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface CopyItemPlatformId {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the id of the given product item
	 */
	item_platform_id: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface CopyStoreId {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
}
export interface CustomerAccountIdInformation {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface CustomerIdCopy {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface CustomerNotFound {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the type of the search performed
	 */
	search_category: SearchCategory
	/**
	 * Represents the value of the search performed
	 */
	search_value: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface CustomerPageViewed {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface CustomerSearch {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface CustomerItem {
	/**
	 * The customer ID.
	 */
	customer_id: string | null
	/**
	 * Email used to perform the search. If the email selected set True. If not, set False.
	 */
	email: boolean | null
	/**
	 * Phone used to perform the search. If the phone selected set True. If not, set False.
	 */
	phone: boolean | null
}
export interface CustomerSearchList {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * The array of customer. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	customer: CustomerItem[] | null
	/**
	 * The number of the page being viewed. Samples: 3
	 */
	page_number: number | null
	/**
	 * The position in the page. It MUST start at 1, NOT at 0. If it's a pagination context, it won't be position in the given page, it will be ABSOLUTE position across the pages. Samples: 1, 8, 4
	 */
	position: number
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface CustomerSearchListBack {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface CustomerSearchListError {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * 
Communication Service error description for 400 and 500 status. Samples: "EMPTY_FIELDS", "The email request has invalid information."
	 */
	failed_reason: string
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface CustomerSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the ID of the current customer
	 */
	customer_id: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface CustomerSelectionSearch {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Method selected to carry out the search
	 */
	search_methodols: SearchCategory
}
export interface CustomersListDownload {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface DeliveryCenterIdCopy {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the ID of the given delivery center
	 */
	delivery_center_id: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface DeliveryScheduleIdCopy {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the ID of the given delivery schedule
	 */
	delivery_schedule_id: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface DeliveryTabSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents every tab in the Account Delivery Page
	 */
	delivery_tab: DeliveryTab
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface DeliveryWindowPageViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface ElementClicked {
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name: string | null
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id: string | null
	/**
	 * The element text on the front end with localization.
	 */
	element_label: string | null
	/**
	 * The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name.
	 */
	element_location_name: string | null
	/**
	 * The category of the object that anchors the button.
	 */
	element_location_type: ElementLocationType
	/**
	 * The element name on the back end.
	 */
	element_name: string | null
	/**
	 * The element type.
	 */
	element_type: ElementType
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event: boolean | null
	/**
	 * (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The Page title on the front end with localization
	 */
	page_label: string | null
	/**
	 * The name of the page where the event was triggered
	 */
	page_name: PageName
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country: string | null
	/**
	 * The user email in the given context. Samples: 'user@bees.com'
	 */
	user_email: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed
	 */
	user_permission_group: string | null
	/**
	 * (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * (IF APPLICABLE) The view level the action is being executed.
	 */
	view_level: string | null
}
export interface EmptyCart {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current truck cart
	 */
	truck_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface HomepageFeatureSelected {
	/**
	 * Represents every feature available in the Apiary Home Page
	 */
	apiary_feature: ApiaryFeature
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface HomepageViewed {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface InvoiceDetailsPageViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the number of the given invoice
	 */
	invoice_number: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface InvoiceNotFound {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the number of the given invoice
	 */
	invoice_number: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface InvoicePriceDetailsModal {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the number of the given invoice
	 */
	invoice_number: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface InvoiceSearch {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface InvoiceSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface InvoicesNotFound {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface InvoicesPageViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface ItemCategoriesModal {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface ItemListDownload {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the id of the given product item
	 */
	item_platform_id: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface ItemNotFound {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
	/**
	 * Represents the item in the store that is being sold by a vendor
	 */
	vendor_item_id: string | null
}
export interface ItemSearch {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
	/**
	 * Represents the item in the store that is being sold by a vendor
	 */
	vendor_item_id: string | null
}
export interface ItemSearchClear {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface ItemsPageViewed {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
}
export interface ItemsPerPageSelected {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Number of Items per page
	 */
	items_per_page: number
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface OpenCategoryModal {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the id of the given product item
	 */
	item_platform_id: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface OpenProductCard {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the id of the given product item
	 */
	item_platform_id: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface OrderDetailsPageViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the number of the given order
	 */
	order_number: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface OrderHistoryModal {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the number of the given order
	 */
	order_number: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface OrderSearch {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the number of the given order
	 */
	order_number: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface OrderSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the number of the given order
	 */
	order_number: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface OrdersNotFound {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the number of the given order
	 */
	order_number: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface OrdersPageViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface PaginationSelected {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * The navigation arrow.
	 */
	navigation_arrows: NavigationArrows
	/**
	 * The number of the page being viewed. Samples: 3
	 */
	page_number: number | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface ProductSearch {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the sku of the products on which the search is performed
	 */
	product_sku: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface ProductsNotFound {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface ProductsPageViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface RemoveAllItems {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current truck cart
	 */
	truck_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface RemoveItem {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the id of the given product item
	 */
	item_platform_id: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current truck cart
	 */
	truck_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface RewardsChallengeIdSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * The challenge ID.
	 */
	challenge_id?: string
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface RewardsChallengesFeatureSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface RewardsGeneralFeatureSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface RewardsNotFound {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface RewardsPageViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface RewardsTabSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents every tab in the Account Rewards Page
	 */
	rewards_tab: RewardsTab
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface RewardsTransactionIdSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the given transaction
	 */
	transaction_id: string | null
}
export interface RewardsTransactionsFeatureSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface SearchAccountId {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface ShowTruckAlert {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current truck cart
	 */
	truck_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface StoreHomepageViewed {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
}
export interface StoreListPageViewed {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The array of stores id. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	store_list: any[] | null
}
export interface StoreSelected {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
}
export interface StoreToolFeatureSelected {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents every feature available in the Store Tools Page
	 */
	store_feature: StoreFeature
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
}
export interface StoreVendorPageViewed {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface StoresNotFound {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
}
export interface SuccessfulCustomerSearch {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the type of the search performed
	 */
	search_category: SearchCategory
	/**
	 * Represents the value of the search performed
	 */
	search_value: string | null
}
export interface TransactionSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the given transaction
	 */
	transaction_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface TransactionsNotFound {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface TruckAlertsTabSelect {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents every tab in the Truck alerts modal in the Truck Simulation and Review Page
	 */
	truck_alerts_tab: TruckAlertsTab
	/**
	 * Represents the ID of the current truck cart
	 */
	truck_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface TruckReviewFeatureSelect {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the kind of information being shown in the Truck Review Page
	 */
	truck_feature: TruckFeature
	/**
	 * Represents the ID of the current truck cart
	 */
	truck_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface TruckReviewPageViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current truck cart
	 */
	truck_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface TruckSimulationPageViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface UpdateItem {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the id of the given product item
	 */
	item_platform_id: string | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current truck cart
	 */
	truck_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface UseCaseSelected {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the type of recommendation that will be retrieved by the Algo-Selling ms
	 */
	use_case: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface VendoAccountSearch {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface VendoTransactionViewed {
	/**
	 * Represents the ID of the current account
	 */
	account_id: string | null
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * The array of vendors transactions. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	vendo_transaction_list: any[] | null
}
export interface VendorChange {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface VendorSelected {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}
export interface VendorsFilterSearch {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * The number of the page being viewed. Samples: 3
	 */
	page_number: number | null
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
}
export interface VendorsNotFound {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the information attached to the current error
	 */
	error: string | null
	/**
	 * Represents the information on the account search
	 */
	id_number: string | null
	/**
	 * Represents the type of account search that is performed
	 */
	id_type: IdType
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * Represents the ID of the current store
	 */
	store_id: string | null
}
export interface VendorsPageViewed {
	/**
	 * Country selected to perform the search.
	 */
	country: Country
	/**
	 * Represents the name of the screen where the user is taking this action
	 */
	screen_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
}

export enum Country {
	Ar = 'AR',
	Bb = 'BB',
	Be = 'BE',
	Bo = 'BO',
	Br = 'BR',
	Ca = 'CA',
	Co = 'CO',
	De = 'DE',
	Do = 'DO',
	Ec = 'EC',
	Gb = 'GB',
	Hn = 'HN',
	Kr = 'KR',
	Mx = 'MX',
	Pa = 'PA',
	Pe = 'PE',
	Py = 'PY',
	Sv = 'SV',
	Tz = 'TZ',
	Ug = 'UG',
	Uk = 'UK',
	Us = 'US',
	Uy = 'UY',
	Za = 'ZA',
	Null = 'null',
}
export enum AccountDetailsTab {
	About = 'ABOUT',
	AccountOwner = 'ACCOUNT_OWNER',
	Credit = 'CREDIT',
	LiquorLicense = 'LIQUOR_LICENSE',
	SalesRep = 'SALES_REP',
	Null = 'null',
}
export enum AccountFeature {
	AccountDetails = 'ACCOUNT_DETAILS',
	AlgoSelling = 'ALGO_SELLING',
	Customers = 'CUSTOMERS',
	Delivery = 'DELIVERY',
	Invoices = 'INVOICES',
	Orders = 'ORDERS',
	Products = 'PRODUCTS',
	Rewards = 'REWARDS',
	TruckSimulation = 'TRUCK_SIMULATION',
	Null = 'null',
}
export enum IdType {
	AccountId = 'ACCOUNT_ID',
	CustomerAccountId = 'CUSTOMER_ACCOUNT_ID',
	Null = 'null',
}
export enum FindAccount {
	AccountId = 'ACCOUNT_ID',
	CustomerAccount = 'CUSTOMER_ACCOUNT',
	Null = 'null',
}
export enum SearchCategory {
	Email = 'EMAIL',
	Name = 'NAME',
	PhoneNumber = 'PHONE_NUMBER',
	Null = 'null',
}
export enum DeliveryTab {
	DeliveryWindow = 'DELIVERY_WINDOW',
	GeneralInformation = 'GENERAL_INFORMATION',
	Null = 'null',
}
export enum ElementLocationType {
	Page = 'PAGE',
	Modal = 'MODAL',
	Menu = 'MENU',
	Chip = 'CHIP',
	TopBar = 'TOP_BAR',
	Null = 'null',
}
export enum ElementType {
	Button = 'BUTTON',
	Tab = 'TAB',
	Card = 'CARD',
	MenuOption = 'MENU_OPTION',
	Breadcrumbs = 'BREADCRUMBS',
	Link = 'LINK',
	Toggle = 'TOGGLE',
	Hotspot = 'HOTSPOT',
	ListItem = 'LIST_ITEM',
	Other = 'OTHER',
	Null = 'null',
}
export enum PageName {
	Home = 'HOME',
	Details = 'DETAILS',
	PageName = 'PAGE_NAME',
	Null = 'null',
}
export enum ApiaryFeature {
	Account = 'ACCOUNT',
	BeesVendo = 'BEES_VENDO',
	Customer = 'CUSTOMER',
	Store = 'STORE',
	Vendor = 'VENDOR',
	Null = 'null',
}
export enum NavigationArrows {
	First = 'FIRST',
	Last = 'LAST',
	Next = 'NEXT',
	Previous = 'PREVIOUS',
	Null = 'null',
}
export enum RewardsTab {
	Challenges = 'CHALLENGES',
	General = 'GENERAL',
	Transactions = 'TRANSACTIONS',
	Null = 'null',
}
export enum StoreFeature {
	Categories = 'CATEGORIES',
	Items = 'ITEMS',
	Vendors = 'VENDORS',
	Null = 'null',
}
export enum TruckAlertsTab {
	Errors = 'ERRORS',
	Information = 'INFORMATION',
	Warnings = 'WARNINGS',
	Null = 'null',
}
export enum TruckFeature {
	Checkout = 'CHECKOUT',
	Truck = 'TRUCK',
	Null = 'null',
}

export type ViolationHandler = (
	message: Segment.TrackMessage<Record<string, any>>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * If NODE_ENV="test", this handler will throw an error. Otherwise, it will log
 * a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	if (process.env.NODE_ENV === 'test') {
		throw new Error(msg)
	}
	console.warn(msg)
}

const missingAnalyticsNodeError = new Error(`You must set an analytics-node instance:

>	const SegmentAnalytics = require('analytics-node')
>	const { setTypewriterOptions } = require('./analytics')
>
>	const analytics = new SegmentAnalytics('SEGMENT_WRITE_KEY')
>	setTypewriterOptions({
>		analytics: analytics,
>	})

For more information on analytics-node, see: https://segment.com/docs/sources/server/node/quickstart/
`)

let analytics: () => Segment.AnalyticsNode | undefined = () => {
	throw missingAnalyticsNodeError
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 */
	analytics: Segment.AnalyticsNode
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 * This function must be called with a configured analytics-node instance before firing
 * any analytics calls, or else a `missingAnalyticsNodeError` error will be thrown.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsNode} analytics - Underlying analytics instance where analytics
 * 		calls are forwarded on to.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics ? () => options.analytics : analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext<P, T extends Segment.TrackMessage<P>>(
	message: T
): T {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.0.2-0',
			},
		},
	}
}

/**
 * A message payload for an analytics-node `.track()` call.
 * See: https://segment.com/docs/spec/track/
 *
 * @typedef TrackMessage<PropertiesType>
 * @property {string | number} [userId] - The ID for this user in your database.
 * @property {string | number} [anonymousId] - An ID to associated with the user when you don’t know who they are.
 * @property {PropertiesType} [properties] - A dictionary of properties for the event.
 * @property {Date} [timestamp] - A Javascript date object representing when the track took place. If the track
 * 		just happened, leave it out and we’ll use the server’s time. If you’re importing data from the past make
 * 		sure you to send a timestamp.
 * @template PropertiesType
 */

/**
 * @typedef AccountCustomerDetailsPageViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} customer_id - Represents the ID of the current customer
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef AccountCustomersPageViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef AccountDetailsTabSelected
 * @property {AccountDetailsTab} account_details_tab - Represents every tab in the Account Details Page
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef AccountDetailsViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef AccountFeatureSelected
 * @property {AccountFeature} account_feature - Represents every feature available in the Account Tools Page
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef AccountHomepageFeatureSelected
 * @property {AccountFeature} account_feature - Represents every feature available in the Account Tools Page
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef AccountHomepageViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef AccountIdError
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef AccountIdInformation
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef AccountNotFound
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} id_number - Represents the information on the account search
 * @property {IdType} id_type - Represents the type of account search that is performed
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef AccountPageViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {FindAccount} find_account - In order to find the account. Sample: ACCOUNT_ID, CUSTOMER_ACCOUNT
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef AccountSearch
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef AddOrUpdateItemToCart
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {any[] | null} item_list - The array of items list. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef AlgoSellingViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef AssociatedAccountSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef AssociatedAccountsNotFound
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} customer_id - Represents the ID of the current customer
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef BeesVendoPageViewed
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef CatalogNotFound
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef CategoriesListNotFound
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 */
/**
 * @typedef CategoriesPageViewed
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 */
/**
 * @typedef CategorySelect
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 */
/**
 * @typedef ChallengeSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {string} [challenge_id] - The challenge ID.
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef ChallengesNotFound
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef CheckoutDeliveryDateSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} truck_id - Represents the ID of the current truck cart
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef CheckoutPaymentMethodSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} truck_id - Represents the ID of the current truck cart
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef ClearSearch
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef CopyItemPlatformId
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} item_platform_id - Represents the id of the given product item
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef CopyStoreId
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 */
/**
 * @typedef CustomerAccountIdInformation
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef CustomerIdCopy
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef CustomerNotFound
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {SearchCategory} search_category - Represents the type of the search performed
 * @property {string | null} search_value - Represents the value of the search performed
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef CustomerPageViewed
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef CustomerSearch
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef CustomerItem
 * @property {string | null} customer_id - The customer ID.
 * @property {boolean | null} email - Email used to perform the search. If the email selected set True. If not, set False.
 * @property {boolean | null} phone - Phone used to perform the search. If the phone selected set True. If not, set False.
 */
/**
 * @typedef CustomerSearchList
 * @property {Country} country - Country selected to perform the search.
 * @property {CustomerItem[] | null} customer - The array of customer. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {number | null} page_number - The number of the page being viewed. Samples: 3
 * @property {number} position - The position in the page. It MUST start at 1, NOT at 0. If it's a pagination context, it won't be position in the given page, it will be ABSOLUTE position across the pages. Samples: 1, 8, 4
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef CustomerSearchListBack
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef CustomerSearchListError
 * @property {Country} country - Country selected to perform the search.
 * @property {string} failed_reason - 
Communication Service error description for 400 and 500 status. Samples: "EMPTY_FIELDS", "The email request has invalid information."
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action                    
 */
/**
 * @typedef CustomerSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} customer_id - Represents the ID of the current customer
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef CustomerSelectionSearch
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {SearchCategory} search_methodols - Method selected to carry out the search
 */
/**
 * @typedef CustomersListDownload
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef DeliveryCenterIdCopy
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} delivery_center_id - Represents the ID of the given delivery center
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef DeliveryScheduleIdCopy
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} delivery_schedule_id - Represents the ID of the given delivery schedule
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef DeliveryTabSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {DeliveryTab} delivery_tab - Represents every tab in the Account Delivery Page
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef DeliveryWindowPageViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef ElementClicked
 * @property {string | null} app_display_name - The current name of the app where the event was triggered
 * @property {string | null} app_id - The unique id of the app where the event was triggered
 * @property {string | null} element_label - The element text on the front end with localization.
 * @property {string | null} element_location_name - The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name.
 * @property {ElementLocationType} element_location_type - The category of the object that anchors the button.
 * @property {string | null} element_name - The element name on the back end.
 * @property {ElementType} element_type - The element type.
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} manufacturer_country - (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} page_label - The Page title on the front end with localization
 * @property {PageName} page_name - The name of the page where the event was triggered
 * @property {string | null} page_url - The URL of the page where the event was triggered
 * @property {string | null} user_country - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} user_email - The user email in the given context. Samples: 'user@bees.com'
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed
 * @property {string | null} vendor_country - (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_service_model - (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} view_level - (IF APPLICABLE) The view level the action is being executed.
 */
/**
 * @typedef EmptyCart
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} truck_id - Represents the ID of the current truck cart
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef HomepageFeatureSelected
 * @property {ApiaryFeature} apiary_feature - Represents every feature available in the Apiary Home Page
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef HomepageViewed
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef InvoiceDetailsPageViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} invoice_number - Represents the number of the given invoice
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef InvoiceNotFound
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} invoice_number - Represents the number of the given invoice
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef InvoicePriceDetailsModal
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} invoice_number - Represents the number of the given invoice
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef InvoiceSearch
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef InvoiceSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef InvoicesNotFound
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef InvoicesPageViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef ItemCategoriesModal
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef ItemListDownload
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} item_platform_id - Represents the id of the given product item
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef ItemNotFound
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_item_id - Represents the item in the store that is being sold by a vendor
 */
/**
 * @typedef ItemSearch
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_item_id - Represents the item in the store that is being sold by a vendor
 */
/**
 * @typedef ItemSearchClear
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef ItemsPageViewed
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 */
/**
 * @typedef ItemsPerPageSelected
 * @property {Country} country - Country selected to perform the search.
 * @property {number} items_per_page - Number of Items per page
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef OpenCategoryModal
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} item_platform_id - Represents the id of the given product item
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef OpenProductCard
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} item_platform_id - Represents the id of the given product item
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef OrderDetailsPageViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} order_number - Represents the number of the given order
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef OrderHistoryModal
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} order_number - Represents the number of the given order
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef OrderSearch
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} order_number - Represents the number of the given order
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef OrderSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} order_number - Represents the number of the given order
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef OrdersNotFound
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} order_number - Represents the number of the given order
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef OrdersPageViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef PaginationSelected
 * @property {Country} country - Country selected to perform the search.
 * @property {NavigationArrows} navigation_arrows - The navigation arrow.
 * @property {number | null} page_number - The number of the page being viewed. Samples: 3
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef ProductSearch
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} product_sku - Represents the sku of the products on which the search is performed
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef ProductsNotFound
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef ProductsPageViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef RemoveAllItems
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} truck_id - Represents the ID of the current truck cart
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef RemoveItem
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} item_platform_id - Represents the id of the given product item
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} truck_id - Represents the ID of the current truck cart
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef RewardsChallengeIdSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {string} [challenge_id] - The challenge ID.
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef RewardsChallengesFeatureSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef RewardsGeneralFeatureSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef RewardsNotFound
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef RewardsPageViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef RewardsTabSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {RewardsTab} rewards_tab - Represents every tab in the Account Rewards Page
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef RewardsTransactionIdSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} transaction_id - Represents the ID of the given transaction
 */
/**
 * @typedef RewardsTransactionsFeatureSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef SearchAccountId
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef ShowTruckAlert
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} truck_id - Represents the ID of the current truck cart
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef StoreHomepageViewed
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 */
/**
 * @typedef StoreListPageViewed
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {any[] | null} store_list - The array of stores id. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 */
/**
 * @typedef StoreSelected
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 */
/**
 * @typedef StoreToolFeatureSelected
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {StoreFeature} store_feature - Represents every feature available in the Store Tools Page
 * @property {string | null} store_id - Represents the ID of the current store
 */
/**
 * @typedef StoreVendorPageViewed
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef StoresNotFound
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 */
/**
 * @typedef SuccessfulCustomerSearch
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {SearchCategory} search_category - Represents the type of the search performed
 * @property {string | null} search_value - Represents the value of the search performed
 */
/**
 * @typedef TransactionSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} transaction_id - Represents the ID of the given transaction
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef TransactionsNotFound
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef TruckAlertsTabSelect
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {TruckAlertsTab} truck_alerts_tab - Represents every tab in the Truck alerts modal in the Truck Simulation and Review Page
 * @property {string | null} truck_id - Represents the ID of the current truck cart
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef TruckReviewFeatureSelect
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {TruckFeature} truck_feature - Represents the kind of information being shown in the Truck Review Page
 * @property {string | null} truck_id - Represents the ID of the current truck cart
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef TruckReviewPageViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} truck_id - Represents the ID of the current truck cart
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef TruckSimulationPageViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef UpdateItem
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} item_platform_id - Represents the id of the given product item
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} truck_id - Represents the ID of the current truck cart
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef UseCaseSelected
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} use_case - Represents the type of recommendation that will be retrieved by the Algo-Selling ms
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef VendoAccountSearch
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef VendoTransactionViewed
 * @property {string | null} account_id - Represents the ID of the current account
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {any[] | null} vendo_transaction_list - The array of vendors transactions. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 */
/**
 * @typedef VendorChange
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef VendorSelected
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */
/**
 * @typedef VendorsFilterSearch
 * @property {Country} country - Country selected to perform the search.
 * @property {number | null} page_number - The number of the page being viewed. Samples: 3
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 */
/**
 * @typedef VendorsNotFound
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} error - Represents the information attached to the current error
 * @property {string | null} id_number - Represents the information on the account search
 * @property {IdType} id_type - Represents the type of account search that is performed
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} store_id - Represents the ID of the current store
 */
/**
 * @typedef VendorsPageViewed
 * @property {Country} country - Country selected to perform the search.
 * @property {string | null} screen_name - Represents the name of the screen where the user is taking this action
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 */

/**
 * Triggered when the user opens the Customer Details page                                                        

 *
 * @param {TrackMessage<AccountCustomerDetailsPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountCustomerDetailsPageViewed(
	message: Segment.TrackMessage<AccountCustomerDetailsPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Account Customer Details Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the account customers page                                                       

 *
 * @param {TrackMessage<AccountCustomersPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountCustomersPageViewed(
	message: Segment.TrackMessage<AccountCustomersPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Account Customers Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks a specific tab                                                                  

 *
 * @param {TrackMessage<AccountDetailsTabSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountDetailsTabSelected(
	message: Segment.TrackMessage<AccountDetailsTabSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Account Details Tab Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Accounts Details page                                                        

 *
 * @param {TrackMessage<AccountDetailsViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountDetailsViewed(
	message: Segment.TrackMessage<AccountDetailsViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Account Details Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects which Feature will be used to analyze specific information regarding BEES Customer Accounts
 *
 * @param {TrackMessage<AccountFeatureSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountFeatureSelected(
	message: Segment.TrackMessage<AccountFeatureSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Account Feature Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks a specific feature card                                                         

 *
 * @param {TrackMessage<AccountHomepageFeatureSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountHomepageFeatureSelected(
	message: Segment.TrackMessage<AccountHomepageFeatureSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Account Homepage Feature Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Accounts Homepage                                                            

 *
 * @param {TrackMessage<AccountHomepageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountHomepageViewed(
	message: Segment.TrackMessage<AccountHomepageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Account Homepage Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects the "Find Account" button and result in error
 *
 * @param {TrackMessage<AccountIdError>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountIdError(
	message: Segment.TrackMessage<AccountIdError>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Account ID Error',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'What is an Account ID?' label and sees the modal                           


 *
 * @param {TrackMessage<AccountIdInformation>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountIdInformation(
	message: Segment.TrackMessage<AccountIdInformation>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Account Id Information',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'Find Account' button and there is a response with no account found         





 *
 * @param {TrackMessage<AccountNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountNotFound(
	message: Segment.TrackMessage<AccountNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Account Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user sees the breakdown of information about BEES Customer Accounts
 *
 * @param {TrackMessage<AccountPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountPageViewed(
	message: Segment.TrackMessage<AccountPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Account Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects the "Find Account" button
 *
 * @param {TrackMessage<AccountSearch>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountSearch(
	message: Segment.TrackMessage<AccountSearch>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Account Search',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'Add' or 'Update' button on an item                                         

 *
 * @param {TrackMessage<AddOrUpdateItemToCart>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addOrUpdateItemToCart(
	message: Segment.TrackMessage<AddOrUpdateItemToCart>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Add Or Update Item To Cart',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Algo-Selling page
 *
 * @param {TrackMessage<AlgoSellingViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function algoSellingViewed(
	message: Segment.TrackMessage<AlgoSellingViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Algo Selling Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks an item in the account list                                                     

 *
 * @param {TrackMessage<AssociatedAccountSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function associatedAccountSelected(
	message: Segment.TrackMessage<AssociatedAccountSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Associated Account Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user enters the page and doesn't see accounts                                               




 *
 * @param {TrackMessage<AssociatedAccountsNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function associatedAccountsNotFound(
	message: Segment.TrackMessage<AssociatedAccountsNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Associated Accounts Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the BEES Vendo Selection page                                                    

 *
 * @param {TrackMessage<BeesVendoPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function beesVendoPageViewed(
	message: Segment.TrackMessage<BeesVendoPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Bees Vendo Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user enters the page and doesn't see any product                                            

 *
 * @param {TrackMessage<CatalogNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function catalogNotFound(
	message: Segment.TrackMessage<CatalogNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Catalog Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user enters the page and doesn't see an item in the categories list                         

 *
 * @param {TrackMessage<CategoriesListNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoriesListNotFound(
	message: Segment.TrackMessage<CategoriesListNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Categories List Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Categories page                                                              

 *
 * @param {TrackMessage<CategoriesPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoriesPageViewed(
	message: Segment.TrackMessage<CategoriesPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Categories Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks an item name in the categories list                                             

 *
 * @param {TrackMessage<CategorySelect>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categorySelect(
	message: Segment.TrackMessage<CategorySelect>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Category Select',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects a challenge to see its modal                                                   

 *
 * @param {TrackMessage<ChallengeSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function challengeSelected(
	message: Segment.TrackMessage<ChallengeSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Challenge Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user doesn't see an item in the challenges list                                             

 *
 * @param {TrackMessage<ChallengesNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function challengesNotFound(
	message: Segment.TrackMessage<ChallengesNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Challenges Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects a delivery date in the Checkout section                                        

 *
 * @param {TrackMessage<CheckoutDeliveryDateSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutDeliveryDateSelected(
	message: Segment.TrackMessage<CheckoutDeliveryDateSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Checkout Delivery Date Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects a payment method in the Checkout section                                       

 *
 * @param {TrackMessage<CheckoutPaymentMethodSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutPaymentMethodSelected(
	message: Segment.TrackMessage<CheckoutPaymentMethodSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Checkout Payment Method Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'X' button inside the search input                                          




                   

 *
 * @param {TrackMessage<ClearSearch>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clearSearch(
	message: Segment.TrackMessage<ClearSearch>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Clear Search',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the copy button on an item                                                      

 *
 * @param {TrackMessage<CopyItemPlatformId>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function copyItemPlatformId(
	message: Segment.TrackMessage<CopyItemPlatformId>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Copy Item Platform Id',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the copy store id button in the store box                                       

 *
 * @param {TrackMessage<CopyStoreId>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function copyStoreId(
	message: Segment.TrackMessage<CopyStoreId>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Copy Store Id',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user sees the field "I don’t have a Customer Account ID"
 *
 * @param {TrackMessage<CustomerAccountIdInformation>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerAccountIdInformation(
	message: Segment.TrackMessage<CustomerAccountIdInformation>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Customer Account ID Information',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the copy button in the customer account box                                     

 *
 * @param {TrackMessage<CustomerIdCopy>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerIdCopy(
	message: Segment.TrackMessage<CustomerIdCopy>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Customer Id Copy',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user enters the page and there is no items in the customers list                            






 *
 * @param {TrackMessage<CustomerNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerNotFound(
	message: Segment.TrackMessage<CustomerNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Customer Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user views the Customer details and account association page
 *
 * @param {TrackMessage<CustomerPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerPageViewed(
	message: Segment.TrackMessage<CustomerPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Customer Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user perform a customer search.
 *
 * @param {TrackMessage<CustomerSearch>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerSearch(
	message: Segment.TrackMessage<CustomerSearch>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Customer Search',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user views the list with the search result
 *
 * @param {TrackMessage<CustomerSearchList>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerSearchList(
	message: Segment.TrackMessage<CustomerSearchList>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Customer Search List',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user returns to the search bar
 *
 * @param {TrackMessage<CustomerSearchListBack>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerSearchListBack(
	message: Segment.TrackMessage<CustomerSearchListBack>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Customer Search List Back',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user gets an error in the search result
 *
 * @param {TrackMessage<CustomerSearchListError>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerSearchListError(
	message: Segment.TrackMessage<CustomerSearchListError>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Customer Search List Error',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks an item in the customer list                                                    



 *
 * @param {TrackMessage<CustomerSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerSelected(
	message: Segment.TrackMessage<CustomerSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Customer Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user search the Customer details and account association page
 *
 * @param {TrackMessage<CustomerSelectionSearch>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerSelectionSearch(
	message: Segment.TrackMessage<CustomerSelectionSearch>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Customer Selection Search',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the download icon                                                               

 *
 * @param {TrackMessage<CustomersListDownload>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customersListDownload(
	message: Segment.TrackMessage<CustomersListDownload>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Customers List Download',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the copy button in the 'Delivery Center ID' row                                 

 *
 * @param {TrackMessage<DeliveryCenterIdCopy>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliveryCenterIdCopy(
	message: Segment.TrackMessage<DeliveryCenterIdCopy>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Delivery Center Id Copy',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the copy button in the 'Delivery Schedule ID' row                               

 *
 * @param {TrackMessage<DeliveryScheduleIdCopy>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliveryScheduleIdCopy(
	message: Segment.TrackMessage<DeliveryScheduleIdCopy>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Delivery Schedule Id Copy',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects a tab                                                                          

 *
 * @param {TrackMessage<DeliveryTabSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliveryTabSelected(
	message: Segment.TrackMessage<DeliveryTabSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Delivery Tab Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 *                
Triggered when the user opens the Delivery Window page                                                         

 *
 * @param {TrackMessage<DeliveryWindowPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliveryWindowPageViewed(
	message: Segment.TrackMessage<DeliveryWindowPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Delivery Window Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the user clicks on the Element.
 *
 * @param {TrackMessage<ElementClicked>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function elementClicked(
	message: Segment.TrackMessage<ElementClicked>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Element Clicked',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user enters the page and there is no item in the cart                                       

 *
 * @param {TrackMessage<EmptyCart>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emptyCart(
	message: Segment.TrackMessage<EmptyCart>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Empty Cart',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks a specific feature card                                                         



 *
 * @param {TrackMessage<HomepageFeatureSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function homepageFeatureSelected(
	message: Segment.TrackMessage<HomepageFeatureSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Homepage Feature Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the APIary homepage                                                              


 *
 * @param {TrackMessage<HomepageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function homepageViewed(
	message: Segment.TrackMessage<HomepageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Homepage Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the invoice details page                                                         

 *
 * @param {TrackMessage<InvoiceDetailsPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function invoiceDetailsPageViewed(
	message: Segment.TrackMessage<InvoiceDetailsPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Invoice Details Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user enters the page and there is no information about the invoice
 *
 * @param {TrackMessage<InvoiceNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function invoiceNotFound(
	message: Segment.TrackMessage<InvoiceNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Invoice Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the invoice price details modal                                                  

 *
 * @param {TrackMessage<InvoicePriceDetailsModal>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function invoicePriceDetailsModal(
	message: Segment.TrackMessage<InvoicePriceDetailsModal>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Invoice Price Details Modal',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'Search' button                                                             

 *
 * @param {TrackMessage<InvoiceSearch>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function invoiceSearch(
	message: Segment.TrackMessage<InvoiceSearch>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Invoice Search',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks an item in the invoices list                                                    

 *
 * @param {TrackMessage<InvoiceSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function invoiceSelected(
	message: Segment.TrackMessage<InvoiceSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Invoice Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user enters the page or performs a search and there is no item in the invoices list         

 *
 * @param {TrackMessage<InvoicesNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function invoicesNotFound(
	message: Segment.TrackMessage<InvoicesNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Invoices Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Orders page                                                                  

 *
 * @param {TrackMessage<InvoicesPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function invoicesPageViewed(
	message: Segment.TrackMessage<InvoicesPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Invoices Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the category number and opens the category modal                                

 *
 * @param {TrackMessage<ItemCategoriesModal>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemCategoriesModal(
	message: Segment.TrackMessage<ItemCategoriesModal>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Item Categories Modal',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'Download Items List' button                                                

 *
 * @param {TrackMessage<ItemListDownload>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemListDownload(
	message: Segment.TrackMessage<ItemListDownload>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Item List Download',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user performs a search and doesn't see any product                                          

 *
 * @param {TrackMessage<ItemNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemNotFound(
	message: Segment.TrackMessage<ItemNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Item Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user performs a item search and sees the correct items                                      

 *
 * @param {TrackMessage<ItemSearch>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemSearch(
	message: Segment.TrackMessage<ItemSearch>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Item Search',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'X' button in the search input                                              

 *
 * @param {TrackMessage<ItemSearchClear>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemSearchClear(
	message: Segment.TrackMessage<ItemSearchClear>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Item Search Clear',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Store Items Page                                                             

 *
 * @param {TrackMessage<ItemsPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemsPageViewed(
	message: Segment.TrackMessage<ItemsPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Items Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user changes the number of elements retrieved by the pagination component
 *
 * @param {TrackMessage<ItemsPerPageSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemsPerPageSelected(
	message: Segment.TrackMessage<ItemsPerPageSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Items Per Page Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the badge the opens the category modal                                          

 *
 * @param {TrackMessage<OpenCategoryModal>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function openCategoryModal(
	message: Segment.TrackMessage<OpenCategoryModal>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Open Category Modal',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the arrow on the item to see its details                                        

 *
 * @param {TrackMessage<OpenProductCard>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function openProductCard(
	message: Segment.TrackMessage<OpenProductCard>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Open Product Card',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Order Details page                                                           

 *
 * @param {TrackMessage<OrderDetailsPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderDetailsPageViewed(
	message: Segment.TrackMessage<OrderDetailsPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Order Details Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the order history modal                                                          

 *
 * @param {TrackMessage<OrderHistoryModal>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderHistoryModal(
	message: Segment.TrackMessage<OrderHistoryModal>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Order History Modal',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'Search' button                                                             

 *
 * @param {TrackMessage<OrderSearch>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderSearch(
	message: Segment.TrackMessage<OrderSearch>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Order Search',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks an item in the orders list                                                      

 *
 * @param {TrackMessage<OrderSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderSelected(
	message: Segment.TrackMessage<OrderSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Order Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user enters the page or performs a search and there is no item in the orders list           

 *
 * @param {TrackMessage<OrdersNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ordersNotFound(
	message: Segment.TrackMessage<OrdersNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Orders Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Orders page                                                                  

 *
 * @param {TrackMessage<OrdersPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ordersPageViewed(
	message: Segment.TrackMessage<OrdersPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Orders Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user navigates through the pages interacting with the pagination component
 *
 * @param {TrackMessage<PaginationSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paginationSelected(
	message: Segment.TrackMessage<PaginationSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Pagination Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'Search' button                                                             

 *
 * @param {TrackMessage<ProductSearch>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productSearch(
	message: Segment.TrackMessage<ProductSearch>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Product Search',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user enters the page or performs a search and doesn't see items in the product list         

 *
 * @param {TrackMessage<ProductsNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsNotFound(
	message: Segment.TrackMessage<ProductsNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Products Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Products page                                                                

 *
 * @param {TrackMessage<ProductsPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsPageViewed(
	message: Segment.TrackMessage<ProductsPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Products Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'Remove All' button                                                         

 *
 * @param {TrackMessage<RemoveAllItems>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function removeAllItems(
	message: Segment.TrackMessage<RemoveAllItems>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Remove All Items',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'Remove' button in any item                                                 

 *
 * @param {TrackMessage<RemoveItem>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function removeItem(
	message: Segment.TrackMessage<RemoveItem>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Remove Item',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects a specific challenge to analyze all the details for it
 *
 * @param {TrackMessage<RewardsChallengeIdSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rewardsChallengeIdSelected(
	message: Segment.TrackMessage<RewardsChallengeIdSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Rewards Challenge ID Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects the Challenges tab on the Rewards page
 *
 * @param {TrackMessage<RewardsChallengesFeatureSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rewardsChallengesFeatureSelected(
	message: Segment.TrackMessage<RewardsChallengesFeatureSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Rewards Challenges Feature Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects the General tab on the Rewards page
 *
 * @param {TrackMessage<RewardsGeneralFeatureSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rewardsGeneralFeatureSelected(
	message: Segment.TrackMessage<RewardsGeneralFeatureSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Rewards General Feature Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user enters the page and there is no rewards to be seen                                     

 *
 * @param {TrackMessage<RewardsNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rewardsNotFound(
	message: Segment.TrackMessage<RewardsNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Rewards Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user sees the Rewards information
 *
 * @param {TrackMessage<RewardsPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rewardsPageViewed(
	message: Segment.TrackMessage<RewardsPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Rewards Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects a tab                                                                          

 *
 * @param {TrackMessage<RewardsTabSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rewardsTabSelected(
	message: Segment.TrackMessage<RewardsTabSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Rewards Tab Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects a specific transaction to analyze all the details for it
 *
 * @param {TrackMessage<RewardsTransactionIdSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rewardsTransactionIdSelected(
	message: Segment.TrackMessage<RewardsTransactionIdSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Rewards Transaction ID Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects the Transactions tab on the Rewards page
 *
 * @param {TrackMessage<RewardsTransactionsFeatureSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rewardsTransactionsFeatureSelected(
	message: Segment.TrackMessage<RewardsTransactionsFeatureSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Rewards Transactions Feature Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'Search' button in the Change Account ID modal                              

 *
 * @param {TrackMessage<SearchAccountId>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchAccountId(
	message: Segment.TrackMessage<SearchAccountId>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Search Account Id',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'Show alerts' button                                                        

 *
 * @param {TrackMessage<ShowTruckAlert>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function showTruckAlert(
	message: Segment.TrackMessage<ShowTruckAlert>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Show Truck Alert',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Store Homepage                                                               

 *
 * @param {TrackMessage<StoreHomepageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function storeHomepageViewed(
	message: Segment.TrackMessage<StoreHomepageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Store Homepage Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Stores List page                                                             

 *
 * @param {TrackMessage<StoreListPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function storeListPageViewed(
	message: Segment.TrackMessage<StoreListPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Store List Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks a specific item in the stores list                                              

 *
 * @param {TrackMessage<StoreSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function storeSelected(
	message: Segment.TrackMessage<StoreSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Store Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks a specific feature card                                                         

 *
 * @param {TrackMessage<StoreToolFeatureSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function storeToolFeatureSelected(
	message: Segment.TrackMessage<StoreToolFeatureSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Store Tool Feature Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Store Vendors page                                                           

 *
 * @param {TrackMessage<StoreVendorPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function storeVendorPageViewed(
	message: Segment.TrackMessage<StoreVendorPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Store Vendor Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user enters the page and doesn't see an item in the stores list                             

 *
 * @param {TrackMessage<StoresNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function storesNotFound(
	message: Segment.TrackMessage<StoresNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Stores Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'Search' button and sees a list of customers                                




 *
 * @param {TrackMessage<SuccessfulCustomerSearch>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function successfulCustomerSearch(
	message: Segment.TrackMessage<SuccessfulCustomerSearch>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Successful Customer Search',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects a transaction to see its modal                                                 

 *
 * @param {TrackMessage<TransactionSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionSelected(
	message: Segment.TrackMessage<TransactionSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Transaction Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user doesn't see an item in the transactions list                                           

 *
 * @param {TrackMessage<TransactionsNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionsNotFound(
	message: Segment.TrackMessage<TransactionsNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Transactions Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects a tab in the alerts modal                                                      

 *
 * @param {TrackMessage<TruckAlertsTabSelect>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function truckAlertsTabSelect(
	message: Segment.TrackMessage<TruckAlertsTabSelect>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Truck Alerts Tab Select',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the radio button and switchs the information displayed                          

 *
 * @param {TrackMessage<TruckReviewFeatureSelect>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function truckReviewFeatureSelect(
	message: Segment.TrackMessage<TruckReviewFeatureSelect>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Truck Review Feature Select',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Truck Review page                                                            

 *
 * @param {TrackMessage<TruckReviewPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function truckReviewPageViewed(
	message: Segment.TrackMessage<TruckReviewPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Truck Review Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the Truck Simulation page                                                        

 *
 * @param {TrackMessage<TruckSimulationPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function truckSimulationPageViewed(
	message: Segment.TrackMessage<TruckSimulationPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Truck Simulation Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'Update' button in any item                                                 

 *
 * @param {TrackMessage<UpdateItem>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function updateItem(
	message: Segment.TrackMessage<UpdateItem>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Update Item',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects an option on the useCase dropdown component
 *
 * @param {TrackMessage<UseCaseSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function useCaseSelected(
	message: Segment.TrackMessage<UseCaseSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Use Case Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks the 'Access Account' button                                                     

 *
 * @param {TrackMessage<VendoAccountSearch>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendoAccountSearch(
	message: Segment.TrackMessage<VendoAccountSearch>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Vendo Account Search',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user opens the BEES Vendo Transaction page                                                  

 *
 * @param {TrackMessage<VendoTransactionViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendoTransactionViewed(
	message: Segment.TrackMessage<VendoTransactionViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Vendo Transaction Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user selects a different vendor in the vendor dropdown list                                 

 *
 * @param {TrackMessage<VendorChange>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorChange(
	message: Segment.TrackMessage<VendorChange>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Vendor Change',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user clicks an item on the vendor list                                                      



 *
 * @param {TrackMessage<VendorSelected>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorSelected(
	message: Segment.TrackMessage<VendorSelected>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Vendor Selected',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user performs the filter search by name or Vendor Id
 *
 * @param {TrackMessage<VendorsFilterSearch>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorsFilterSearch(
	message: Segment.TrackMessage<VendorsFilterSearch>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Vendors Filter Search',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user enters the page and doesn't see vendors                                                





 *
 * @param {TrackMessage<VendorsNotFound>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorsNotFound(
	message: Segment.TrackMessage<VendorsNotFound>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Vendors Not Found',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Triggered when the user views All details and products offered by a vendor.
 *
 * @param {TrackMessage<VendorsPageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorsPageViewed(
	message: Segment.TrackMessage<VendorsPageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Vendors Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 * This function must be called with a configured analytics-node instance before firing
	 * any analytics calls, or else a `missingAnalyticsNodeError` error will be thrown.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsNode} analytics - Underlying analytics instance where analytics
	 * 		calls are forwarded on to.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Triggered when the user opens the Customer Details page                                                        
	
	 *
	 * @param {TrackMessage<AccountCustomerDetailsPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountCustomerDetailsPageViewed,
	/**
	 * Triggered when the user opens the account customers page                                                       
	
	 *
	 * @param {TrackMessage<AccountCustomersPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountCustomersPageViewed,
	/**
	 * Triggered when the user clicks a specific tab                                                                  
	
	 *
	 * @param {TrackMessage<AccountDetailsTabSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountDetailsTabSelected,
	/**
	 * Triggered when the user opens the Accounts Details page                                                        
	
	 *
	 * @param {TrackMessage<AccountDetailsViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountDetailsViewed,
	/**
	 * Triggered when the user selects which Feature will be used to analyze specific information regarding BEES Customer Accounts
	 *
	 * @param {TrackMessage<AccountFeatureSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountFeatureSelected,
	/**
	 * Triggered when the user clicks a specific feature card                                                         
	
	 *
	 * @param {TrackMessage<AccountHomepageFeatureSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountHomepageFeatureSelected,
	/**
	 * Triggered when the user opens the Accounts Homepage                                                            
	
	 *
	 * @param {TrackMessage<AccountHomepageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountHomepageViewed,
	/**
	 * Triggered when the user selects the "Find Account" button and result in error
	 *
	 * @param {TrackMessage<AccountIdError>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountIdError,
	/**
	 * Triggered when the user clicks the 'What is an Account ID?' label and sees the modal                           
	
	
	 *
	 * @param {TrackMessage<AccountIdInformation>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountIdInformation,
	/**
	 * Triggered when the user clicks the 'Find Account' button and there is a response with no account found         
	
	
	
	
	
	 *
	 * @param {TrackMessage<AccountNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountNotFound,
	/**
	 * Triggered when the user sees the breakdown of information about BEES Customer Accounts
	 *
	 * @param {TrackMessage<AccountPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountPageViewed,
	/**
	 * Triggered when the user selects the "Find Account" button
	 *
	 * @param {TrackMessage<AccountSearch>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountSearch,
	/**
	 * Triggered when the user clicks the 'Add' or 'Update' button on an item                                         
	
	 *
	 * @param {TrackMessage<AddOrUpdateItemToCart>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addOrUpdateItemToCart,
	/**
	 * Triggered when the user opens the Algo-Selling page
	 *
	 * @param {TrackMessage<AlgoSellingViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	algoSellingViewed,
	/**
	 * Triggered when the user clicks an item in the account list                                                     
	
	 *
	 * @param {TrackMessage<AssociatedAccountSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	associatedAccountSelected,
	/**
	 * Triggered when the user enters the page and doesn't see accounts                                               
	
	
	
	
	 *
	 * @param {TrackMessage<AssociatedAccountsNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	associatedAccountsNotFound,
	/**
	 * Triggered when the user opens the BEES Vendo Selection page                                                    
	
	 *
	 * @param {TrackMessage<BeesVendoPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	beesVendoPageViewed,
	/**
	 * Triggered when the user enters the page and doesn't see any product                                            
	
	 *
	 * @param {TrackMessage<CatalogNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	catalogNotFound,
	/**
	 * Triggered when the user enters the page and doesn't see an item in the categories list                         
	
	 *
	 * @param {TrackMessage<CategoriesListNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoriesListNotFound,
	/**
	 * Triggered when the user opens the Categories page                                                              
	
	 *
	 * @param {TrackMessage<CategoriesPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoriesPageViewed,
	/**
	 * Triggered when the user clicks an item name in the categories list                                             
	
	 *
	 * @param {TrackMessage<CategorySelect>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categorySelect,
	/**
	 * Triggered when the user selects a challenge to see its modal                                                   
	
	 *
	 * @param {TrackMessage<ChallengeSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	challengeSelected,
	/**
	 * Triggered when the user doesn't see an item in the challenges list                                             
	
	 *
	 * @param {TrackMessage<ChallengesNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	challengesNotFound,
	/**
	 * Triggered when the user selects a delivery date in the Checkout section                                        
	
	 *
	 * @param {TrackMessage<CheckoutDeliveryDateSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutDeliveryDateSelected,
	/**
	 * Triggered when the user selects a payment method in the Checkout section                                       
	
	 *
	 * @param {TrackMessage<CheckoutPaymentMethodSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutPaymentMethodSelected,
	/**
	 * Triggered when the user clicks the 'X' button inside the search input                                          
	
	
	
	
	                   
	
	 *
	 * @param {TrackMessage<ClearSearch>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clearSearch,
	/**
	 * Triggered when the user clicks the copy button on an item                                                      
	
	 *
	 * @param {TrackMessage<CopyItemPlatformId>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	copyItemPlatformId,
	/**
	 * Triggered when the user clicks the copy store id button in the store box                                       
	
	 *
	 * @param {TrackMessage<CopyStoreId>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	copyStoreId,
	/**
	 * Triggered when the user sees the field "I don’t have a Customer Account ID"
	 *
	 * @param {TrackMessage<CustomerAccountIdInformation>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerAccountIdInformation,
	/**
	 * Triggered when the user clicks the copy button in the customer account box                                     
	
	 *
	 * @param {TrackMessage<CustomerIdCopy>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerIdCopy,
	/**
	 * Triggered when the user enters the page and there is no items in the customers list                            
	
	
	
	
	
	
	 *
	 * @param {TrackMessage<CustomerNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerNotFound,
	/**
	 * Triggered when the user views the Customer details and account association page
	 *
	 * @param {TrackMessage<CustomerPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerPageViewed,
	/**
	 * Triggered when the user perform a customer search.
	 *
	 * @param {TrackMessage<CustomerSearch>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerSearch,
	/**
	 * Triggered when the user views the list with the search result
	 *
	 * @param {TrackMessage<CustomerSearchList>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerSearchList,
	/**
	 * Triggered when the user returns to the search bar
	 *
	 * @param {TrackMessage<CustomerSearchListBack>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerSearchListBack,
	/**
	 * Triggered when the user gets an error in the search result
	 *
	 * @param {TrackMessage<CustomerSearchListError>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerSearchListError,
	/**
	 * Triggered when the user clicks an item in the customer list                                                    
	
	
	
	 *
	 * @param {TrackMessage<CustomerSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerSelected,
	/**
	 * Triggered when the user search the Customer details and account association page
	 *
	 * @param {TrackMessage<CustomerSelectionSearch>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerSelectionSearch,
	/**
	 * Triggered when the user clicks the download icon                                                               
	
	 *
	 * @param {TrackMessage<CustomersListDownload>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customersListDownload,
	/**
	 * Triggered when the user clicks the copy button in the 'Delivery Center ID' row                                 
	
	 *
	 * @param {TrackMessage<DeliveryCenterIdCopy>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deliveryCenterIdCopy,
	/**
	 * Triggered when the user clicks the copy button in the 'Delivery Schedule ID' row                               
	
	 *
	 * @param {TrackMessage<DeliveryScheduleIdCopy>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deliveryScheduleIdCopy,
	/**
	 * Triggered when the user selects a tab                                                                          
	
	 *
	 * @param {TrackMessage<DeliveryTabSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deliveryTabSelected,
	/**
	 *                
	Triggered when the user opens the Delivery Window page                                                         
	
	 *
	 * @param {TrackMessage<DeliveryWindowPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deliveryWindowPageViewed,
	/**
	 * When the user clicks on the Element.
	 *
	 * @param {TrackMessage<ElementClicked>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	elementClicked,
	/**
	 * Triggered when the user enters the page and there is no item in the cart                                       
	
	 *
	 * @param {TrackMessage<EmptyCart>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	emptyCart,
	/**
	 * Triggered when the user clicks a specific feature card                                                         
	
	
	
	 *
	 * @param {TrackMessage<HomepageFeatureSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	homepageFeatureSelected,
	/**
	 * Triggered when the user opens the APIary homepage                                                              
	
	
	 *
	 * @param {TrackMessage<HomepageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	homepageViewed,
	/**
	 * Triggered when the user opens the invoice details page                                                         
	
	 *
	 * @param {TrackMessage<InvoiceDetailsPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	invoiceDetailsPageViewed,
	/**
	 * Triggered when the user enters the page and there is no information about the invoice
	 *
	 * @param {TrackMessage<InvoiceNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	invoiceNotFound,
	/**
	 * Triggered when the user opens the invoice price details modal                                                  
	
	 *
	 * @param {TrackMessage<InvoicePriceDetailsModal>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	invoicePriceDetailsModal,
	/**
	 * Triggered when the user clicks the 'Search' button                                                             
	
	 *
	 * @param {TrackMessage<InvoiceSearch>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	invoiceSearch,
	/**
	 * Triggered when the user clicks an item in the invoices list                                                    
	
	 *
	 * @param {TrackMessage<InvoiceSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	invoiceSelected,
	/**
	 * Triggered when the user enters the page or performs a search and there is no item in the invoices list         
	
	 *
	 * @param {TrackMessage<InvoicesNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	invoicesNotFound,
	/**
	 * Triggered when the user opens the Orders page                                                                  
	
	 *
	 * @param {TrackMessage<InvoicesPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	invoicesPageViewed,
	/**
	 * Triggered when the user clicks the category number and opens the category modal                                
	
	 *
	 * @param {TrackMessage<ItemCategoriesModal>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemCategoriesModal,
	/**
	 * Triggered when the user clicks the 'Download Items List' button                                                
	
	 *
	 * @param {TrackMessage<ItemListDownload>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemListDownload,
	/**
	 * Triggered when the user performs a search and doesn't see any product                                          
	
	 *
	 * @param {TrackMessage<ItemNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemNotFound,
	/**
	 * Triggered when the user performs a item search and sees the correct items                                      
	
	 *
	 * @param {TrackMessage<ItemSearch>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemSearch,
	/**
	 * Triggered when the user clicks the 'X' button in the search input                                              
	
	 *
	 * @param {TrackMessage<ItemSearchClear>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemSearchClear,
	/**
	 * Triggered when the user opens the Store Items Page                                                             
	
	 *
	 * @param {TrackMessage<ItemsPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemsPageViewed,
	/**
	 * Triggered when the user changes the number of elements retrieved by the pagination component
	 *
	 * @param {TrackMessage<ItemsPerPageSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemsPerPageSelected,
	/**
	 * Triggered when the user clicks the badge the opens the category modal                                          
	
	 *
	 * @param {TrackMessage<OpenCategoryModal>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	openCategoryModal,
	/**
	 * Triggered when the user clicks the arrow on the item to see its details                                        
	
	 *
	 * @param {TrackMessage<OpenProductCard>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	openProductCard,
	/**
	 * Triggered when the user opens the Order Details page                                                           
	
	 *
	 * @param {TrackMessage<OrderDetailsPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderDetailsPageViewed,
	/**
	 * Triggered when the user opens the order history modal                                                          
	
	 *
	 * @param {TrackMessage<OrderHistoryModal>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderHistoryModal,
	/**
	 * Triggered when the user clicks the 'Search' button                                                             
	
	 *
	 * @param {TrackMessage<OrderSearch>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderSearch,
	/**
	 * Triggered when the user clicks an item in the orders list                                                      
	
	 *
	 * @param {TrackMessage<OrderSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderSelected,
	/**
	 * Triggered when the user enters the page or performs a search and there is no item in the orders list           
	
	 *
	 * @param {TrackMessage<OrdersNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ordersNotFound,
	/**
	 * Triggered when the user opens the Orders page                                                                  
	
	 *
	 * @param {TrackMessage<OrdersPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ordersPageViewed,
	/**
	 * Triggered when the user navigates through the pages interacting with the pagination component
	 *
	 * @param {TrackMessage<PaginationSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paginationSelected,
	/**
	 * Triggered when the user clicks the 'Search' button                                                             
	
	 *
	 * @param {TrackMessage<ProductSearch>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productSearch,
	/**
	 * Triggered when the user enters the page or performs a search and doesn't see items in the product list         
	
	 *
	 * @param {TrackMessage<ProductsNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsNotFound,
	/**
	 * Triggered when the user opens the Products page                                                                
	
	 *
	 * @param {TrackMessage<ProductsPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsPageViewed,
	/**
	 * Triggered when the user clicks the 'Remove All' button                                                         
	
	 *
	 * @param {TrackMessage<RemoveAllItems>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	removeAllItems,
	/**
	 * Triggered when the user clicks the 'Remove' button in any item                                                 
	
	 *
	 * @param {TrackMessage<RemoveItem>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	removeItem,
	/**
	 * Triggered when the user selects a specific challenge to analyze all the details for it
	 *
	 * @param {TrackMessage<RewardsChallengeIdSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	rewardsChallengeIdSelected,
	/**
	 * Triggered when the user selects the Challenges tab on the Rewards page
	 *
	 * @param {TrackMessage<RewardsChallengesFeatureSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	rewardsChallengesFeatureSelected,
	/**
	 * Triggered when the user selects the General tab on the Rewards page
	 *
	 * @param {TrackMessage<RewardsGeneralFeatureSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	rewardsGeneralFeatureSelected,
	/**
	 * Triggered when the user enters the page and there is no rewards to be seen                                     
	
	 *
	 * @param {TrackMessage<RewardsNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	rewardsNotFound,
	/**
	 * Triggered when the user sees the Rewards information
	 *
	 * @param {TrackMessage<RewardsPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	rewardsPageViewed,
	/**
	 * Triggered when the user selects a tab                                                                          
	
	 *
	 * @param {TrackMessage<RewardsTabSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	rewardsTabSelected,
	/**
	 * Triggered when the user selects a specific transaction to analyze all the details for it
	 *
	 * @param {TrackMessage<RewardsTransactionIdSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	rewardsTransactionIdSelected,
	/**
	 * Triggered when the user selects the Transactions tab on the Rewards page
	 *
	 * @param {TrackMessage<RewardsTransactionsFeatureSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	rewardsTransactionsFeatureSelected,
	/**
	 * Triggered when the user clicks the 'Search' button in the Change Account ID modal                              
	
	 *
	 * @param {TrackMessage<SearchAccountId>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchAccountId,
	/**
	 * Triggered when the user clicks the 'Show alerts' button                                                        
	
	 *
	 * @param {TrackMessage<ShowTruckAlert>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	showTruckAlert,
	/**
	 * Triggered when the user opens the Store Homepage                                                               
	
	 *
	 * @param {TrackMessage<StoreHomepageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	storeHomepageViewed,
	/**
	 * Triggered when the user opens the Stores List page                                                             
	
	 *
	 * @param {TrackMessage<StoreListPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	storeListPageViewed,
	/**
	 * Triggered when the user clicks a specific item in the stores list                                              
	
	 *
	 * @param {TrackMessage<StoreSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	storeSelected,
	/**
	 * Triggered when the user clicks a specific feature card                                                         
	
	 *
	 * @param {TrackMessage<StoreToolFeatureSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	storeToolFeatureSelected,
	/**
	 * Triggered when the user opens the Store Vendors page                                                           
	
	 *
	 * @param {TrackMessage<StoreVendorPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	storeVendorPageViewed,
	/**
	 * Triggered when the user enters the page and doesn't see an item in the stores list                             
	
	 *
	 * @param {TrackMessage<StoresNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	storesNotFound,
	/**
	 * Triggered when the user clicks the 'Search' button and sees a list of customers                                
	
	
	
	
	 *
	 * @param {TrackMessage<SuccessfulCustomerSearch>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	successfulCustomerSearch,
	/**
	 * Triggered when the user selects a transaction to see its modal                                                 
	
	 *
	 * @param {TrackMessage<TransactionSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionSelected,
	/**
	 * Triggered when the user doesn't see an item in the transactions list                                           
	
	 *
	 * @param {TrackMessage<TransactionsNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionsNotFound,
	/**
	 * Triggered when the user selects a tab in the alerts modal                                                      
	
	 *
	 * @param {TrackMessage<TruckAlertsTabSelect>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	truckAlertsTabSelect,
	/**
	 * Triggered when the user clicks the radio button and switchs the information displayed                          
	
	 *
	 * @param {TrackMessage<TruckReviewFeatureSelect>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	truckReviewFeatureSelect,
	/**
	 * Triggered when the user opens the Truck Review page                                                            
	
	 *
	 * @param {TrackMessage<TruckReviewPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	truckReviewPageViewed,
	/**
	 * Triggered when the user opens the Truck Simulation page                                                        
	
	 *
	 * @param {TrackMessage<TruckSimulationPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	truckSimulationPageViewed,
	/**
	 * Triggered when the user clicks the 'Update' button in any item                                                 
	
	 *
	 * @param {TrackMessage<UpdateItem>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	updateItem,
	/**
	 * Triggered when the user selects an option on the useCase dropdown component
	 *
	 * @param {TrackMessage<UseCaseSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	useCaseSelected,
	/**
	 * Triggered when the user clicks the 'Access Account' button                                                     
	
	 *
	 * @param {TrackMessage<VendoAccountSearch>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendoAccountSearch,
	/**
	 * Triggered when the user opens the BEES Vendo Transaction page                                                  
	
	 *
	 * @param {TrackMessage<VendoTransactionViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendoTransactionViewed,
	/**
	 * Triggered when the user selects a different vendor in the vendor dropdown list                                 
	
	 *
	 * @param {TrackMessage<VendorChange>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorChange,
	/**
	 * Triggered when the user clicks an item on the vendor list                                                      
	
	
	
	 *
	 * @param {TrackMessage<VendorSelected>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorSelected,
	/**
	 * Triggered when the user performs the filter search by name or Vendor Id
	 *
	 * @param {TrackMessage<VendorsFilterSearch>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorsFilterSearch,
	/**
	 * Triggered when the user enters the page and doesn't see vendors                                                
	
	
	
	
	
	 *
	 * @param {TrackMessage<VendorsNotFound>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorsNotFound,
	/**
	 * Triggered when the user views All details and products offered by a vendor.
	 *
	 * @param {TrackMessage<VendorsPageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorsPageViewed,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_2HdI9ZLXQ4EJUJ9Sa89qYvsL2JJ`)
			const a = analytics()
			if (a) {
				a.track(
					withTypewriterContext({
						event: 'Unknown Analytics Call Fired',
						properties: {
							method,
						},
						userId: 'typewriter',
					})
				)
			}
		}
	},
})
