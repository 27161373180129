const BASE_PATH = `/apiary/:countryCode`;

// common
const COUNTRY_SELECTION = `/apiary`;
const ERROR_403 = `${COUNTRY_SELECTION}/403`;
const HOME_TOOLS = `${BASE_PATH}/tools`;

const ACCOUNT_TOOLS = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId`;
const ACCOUNT_SELECTION_HOME = `${BASE_PATH}/accounts/selection`;
const ACCOUNT_SELECTION_STEP_ONE = `${BASE_PATH}/accounts/selection/account/step-one`;
const ACCOUNT_SELECTION_STEP_TWO = `${BASE_PATH}/accounts/selection/account/step-two`;
const CUSTOMER_SELECTION_STEP_ONE = `${BASE_PATH}/accounts/selection/customer/step-one`;
const CUSTOMER_SELECTION_STEP_TWO = `${BASE_PATH}/accounts/selection/customer/step-two`;
const ACCOUNT_INVOICES_LIST = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/invoices`;
const ACCOUNT_INVOICE_DETAILS = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/invoices/:customerInvoiceNumber`;
const ACCOUNT_ORDERS_LIST = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/orders`;
const ACCOUNT_ORDER_DETAILS = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/orders/:orderNumber`;
const ACCOUNT_CUSTOMERS_LIST = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/customers`;
const ACCOUNT_CUSTOMER_DETAILS = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/customers/:customerId`;
const ACCOUNT_TRUCK_REVIEW = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/truck-simulation/:truckId/truck`;
const ACCOUNT_TRUCK_SIMULATION = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/truck-simulation`;
const ACCOUNT_PRODUCTS_LIST = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/products`;
const ACCOUNT_DETAILS = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/details`;
const ACCOUNT_REWARDS = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/rewards`;
const ACCOUNT_REWARD_CHALLENGE_DETAILS = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/rewards/:challengeId`;
const ACCOUNT_ALGO_SELLING = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/algo-selling`;

const STORES_LIST = `${BASE_PATH}/stores`;
const STORE_TOOLS = `${BASE_PATH}/stores/:storeId`;
const STORE_CATEGORIES_LIST = `${BASE_PATH}/stores/:storeId/categories`;
const STORE_CATEGORY_DETAILS = `${BASE_PATH}/stores/:storeId/categories/:categoryId`;
const STORE_ITEMS_LIST = `${BASE_PATH}/stores/:storeId/items`;
const STORE_VENDORS_LIST = `${BASE_PATH}/stores/:storeId/vendors`;

const VENDORS_LIST = `${BASE_PATH}/vendors`;
const VENDOR_TOOLS = `${BASE_PATH}/vendors/:vendorId`;
const VENDOR_DETAILS = `${BASE_PATH}/vendors/:vendorId/details`;
const VENDOR_CATEGORIES_LIST = `${BASE_PATH}/vendors/:vendorId/categories`;
const VENDOR_CATEGORY_DETAILS = `${BASE_PATH}/vendors/:vendorId/categories/:categoryId`;

const BEES_VENDO_SELECTION = `${BASE_PATH}/vendo`;
const BEES_VENDO_TRANSACTIONS = `${BASE_PATH}/vendo/:accountId/transactions`;

// multivendor & multicontract
const ACCOUNT_DELIVERY_WINDOWS = `${BASE_PATH}/accounts/:accountId/vendors/:vendorId/delivery-windows`;

const BEES_FORCE_SEARCH = `${BASE_PATH}/force`;
const BEES_FORCE_ACCOUNT_BDR = `${BASE_PATH}/force/bdr/:bdrId/vendors/:vendorId/seller/:vendorName`;
const BEES_FORCE_BDR_VISITS_LIST = `${BASE_PATH}/force/bdr/:bdrId/vendors/:vendorId/visits`;
const BEES_FORCE_BDR_VISIT_DETAILS = `${BASE_PATH}/force/bdr/:bdrId/vendors/:vendorId/visits/:visitId`;
const BEES_FORCE_BDR_TASKS_LIST = `${BASE_PATH}/force/bdr/:bdrId/vendors/:vendorId/tasks`;
const BEES_FORCE_BDR_TASK_DETAILS = `${BASE_PATH}/force/bdr/:bdrId/vendors/:vendorId/tasks/:taskId`;

export const routes = {
  COUNTRY_SELECTION,
  ERROR_403,
  HOME_TOOLS,
  ACCOUNT_TOOLS,
  ACCOUNT_SELECTION_HOME,
  ACCOUNT_SELECTION_STEP_ONE,
  ACCOUNT_SELECTION_STEP_TWO,
  CUSTOMER_SELECTION_STEP_ONE,
  CUSTOMER_SELECTION_STEP_TWO,
  ACCOUNT_INVOICES_LIST,
  ACCOUNT_INVOICE_DETAILS,
  ACCOUNT_ORDERS_LIST,
  ACCOUNT_ORDER_DETAILS,
  ACCOUNT_CUSTOMERS_LIST,
  ACCOUNT_CUSTOMER_DETAILS,
  ACCOUNT_TRUCK_REVIEW,
  ACCOUNT_TRUCK_SIMULATION,
  ACCOUNT_PRODUCTS_LIST,
  ACCOUNT_DETAILS,
  ACCOUNT_REWARDS,
  ACCOUNT_REWARD_CHALLENGE_DETAILS,
  ACCOUNT_DELIVERY_WINDOWS,
  ACCOUNT_ALGO_SELLING,
  STORES_LIST,
  STORE_TOOLS,
  STORE_CATEGORIES_LIST,
  STORE_CATEGORY_DETAILS,
  STORE_ITEMS_LIST,
  STORE_VENDORS_LIST,
  VENDORS_LIST,
  VENDOR_TOOLS,
  VENDOR_DETAILS,
  VENDOR_CATEGORIES_LIST,
  VENDOR_CATEGORY_DETAILS,
  BEES_VENDO_SELECTION,
  BEES_VENDO_TRANSACTIONS,
  BEES_FORCE_SEARCH,
  BEES_FORCE_ACCOUNT_BDR,
  BEES_FORCE_BDR_VISITS_LIST,
  BEES_FORCE_BDR_VISIT_DETAILS,
  BEES_FORCE_BDR_TASKS_LIST,
  BEES_FORCE_BDR_TASK_DETAILS,
};
