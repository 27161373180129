import { ICountry } from 'interfaces/ICountry';
import { SET_COUNTRY } from 'redux/actionTypes';
import { ISystemAction } from 'redux/interfaces';

export const setCountry = (country?: ICountry): ISystemAction => ({
  type: SET_COUNTRY,
  payload: {
    selectedCountry: country,
  },
});
