import { countryList } from 'consts/countryList';
import { JSX, useMemo } from 'react';
import styled from 'styled-components';
import { countryHelper } from 'utils/helpers';
import { TDateType } from 'utils/helpers/country/formattedDateByLocale';
import { useApiaryParams } from './useApiaryParams';

interface IUseDataFormatterResponse {
  formatCurrency: (value?: number) => string;
  renderCurrencyElement: (value?: number) => JSX.Element;
  formatDate: (type: TDateType, date?: string) => string;
  renderDateElement: (type: TDateType, date?: string) => JSX.Element;
  formatNumber: (value?: number) => string;
}

export enum EDateType {
  SHORT = 'SHORT',
  LONG = 'LONG',
  WEEK = 'WEEK',
  GMT = 'GMT',
  GMT24H = 'GMT24H',
  ISO = 'ISO',
}

const MonetaryCell = styled.span`
  white-space: pre;
`;

export const useDataFormatter = (): IUseDataFormatterResponse => {
  const { countryCode } = useApiaryParams();
  const country = useMemo(
    () => countryList.find((countryItem) => countryItem?.code === countryCode),
    [countryCode]
  );
  // TODO fix format
  const formatCurrency = (value?: number) =>
    !!value || value === 0
      ? countryHelper.formattedCurrencyByLocale({
          value,
          locale: country?.languages?.[0]?.locale,
          symbol: country?.currencies?.[0]?.symbol,
        })
      : '-';

  const formatDate = (type: TDateType, date?: string) =>
    date
      ? countryHelper.formattedDateByLocale({
          date,
          locale: country?.languages?.[0]?.locale,
          type,
        })
      : '-';

  const formatNumber = (value?: number) =>
    !!value || value === 0
      ? countryHelper.formatNumberByLocale({
          value,
          locale: country?.languages?.[0]?.locale,
        })
      : '-';

  const renderCurrencyElement = (value?: number): JSX.Element => {
    const formattedValue = formatCurrency(value);

    return <MonetaryCell>{formattedValue}</MonetaryCell>;
  };

  const renderDateElement = (type: TDateType, date?: string): JSX.Element => (
    <span>{formatDate(type, date)}</span>
  );

  return {
    formatCurrency,
    formatDate,
    formatNumber,
    renderCurrencyElement,
    renderDateElement,
  };
};
