import { IAccountSelection } from 'interfaces/ISelection';
import { SET_ACCOUNT_SELECTED } from 'redux/actionTypes';
import { ISelectAccountAction } from 'redux/interfaces';

export const setAccountSelected = (
  accountSelected?: IAccountSelection | null
): ISelectAccountAction => ({
  type: SET_ACCOUNT_SELECTED,
  payload: {
    accountSelected,
  },
});
