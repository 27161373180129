import { routes } from 'consts/routes';
import { ConnectedWrapper } from 'contexts/ConnectedWrapper';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const {
  COUNTRY_SELECTION,
  HOME_TOOLS,
  ACCOUNT_TOOLS,
  ACCOUNT_SELECTION_HOME,
  ACCOUNT_SELECTION_STEP_ONE,
  ACCOUNT_SELECTION_STEP_TWO,
  CUSTOMER_SELECTION_STEP_ONE,
  CUSTOMER_SELECTION_STEP_TWO,
  ACCOUNT_INVOICES_LIST,
  ACCOUNT_INVOICE_DETAILS,
  ACCOUNT_ORDERS_LIST,
  ACCOUNT_ORDER_DETAILS,
  ACCOUNT_CUSTOMERS_LIST,
  ACCOUNT_CUSTOMER_DETAILS,
  ACCOUNT_TRUCK_REVIEW,
  ACCOUNT_TRUCK_SIMULATION,
  ACCOUNT_PRODUCTS_LIST,
  ACCOUNT_DETAILS,
  ACCOUNT_REWARDS,
  ACCOUNT_REWARD_CHALLENGE_DETAILS,
  ACCOUNT_DELIVERY_WINDOWS,
  ACCOUNT_ALGO_SELLING,
  STORES_LIST,
  STORE_TOOLS,
  STORE_CATEGORIES_LIST,
  STORE_CATEGORY_DETAILS,
  STORE_ITEMS_LIST,
  STORE_VENDORS_LIST,
  VENDORS_LIST,
  VENDOR_TOOLS,
  VENDOR_DETAILS,
  VENDOR_CATEGORIES_LIST,
  VENDOR_CATEGORY_DETAILS,
  BEES_VENDO_SELECTION,
  BEES_VENDO_TRANSACTIONS,
  BEES_FORCE_SEARCH,
  BEES_FORCE_ACCOUNT_BDR,
  BEES_FORCE_BDR_VISITS_LIST,
  BEES_FORCE_BDR_VISIT_DETAILS,
  BEES_FORCE_BDR_TASKS_LIST,
  BEES_FORCE_BDR_TASK_DETAILS,
} = routes;

const CountrySelectionPage = lazy(() => import('pages/CountrySelectionPage'));
const Error403Page = lazy(() => import('pages/403Page'));
const HomeToolsPage = lazy(() => import('pages/HomeToolsPage'));

const AccountToolsPage = lazy(() => import('pages/Account/ToolsPage'));
const AccountSelectionHomePage = lazy(() => import('pages/Account/SelectionStepPages'));
const AccountStepOne = lazy(() => import('pages/Account/SelectionStepPages/pages/AccountStepOne'));
const AccountStepTwo = lazy(() => import('pages/Account/SelectionStepPages/pages/AccountStepTwo'));
const CustomerStepOne = lazy(
  () => import('pages/Account/SelectionStepPages/pages/CustomerStepOne')
);
const CustomerStepTwo = lazy(
  () => import('pages/Account/SelectionStepPages/pages/CustomerStepTwo')
);
const AccountInvoicesListPage = lazy(() => import('pages/Account/InvoicesListPage'));
const AccountInvoiceDetailsPage = lazy(() => import('pages/Account/InvoiceDetailsPage'));
const AccountOrdersListPage = lazy(() => import('pages/Account/OrdersListPage'));
const AccountOrderDetailsPage = lazy(() => import('pages/Account/OrderDetailsPage'));
const AccountCustomersListPage = lazy(() => import('pages/Account/CustomersListPage'));
const AccountCustomerDetailsPage = lazy(() => import('pages/Account/CustomerDetailsPage'));
const AccountTruckReviewPage = lazy(() => import('pages/Account/TruckReviewPage'));
const AccountTruckSimulationPage = lazy(() => import('pages/Account/TruckSimulationPage'));
const AccountProductsListPage = lazy(() => import('pages/Account/ProductsListPage'));
const AccountDetailsPage = lazy(() => import('pages/Account/DetailsPage'));
const AccountRewardsPage = lazy(() => import('pages/Account/RewardsPage'));
const RewardChallengeDetailsPage = lazy(() => import('pages/Account/RewardChallengeDetailsPage'));
const AccountDeliveryWindowsPage = lazy(() => import('pages/Account/DeliveryWindowsPage'));
const AccountAlgoSellingPage = lazy(() => import('pages/Account/AlgoSellingPage'));

const StoresListPage = lazy(() => import('pages/Store/StoresListPage'));
const StoreToolsPage = lazy(() => import('pages/Store/ToolsPage'));
const StoreCategoriesListPage = lazy(() => import('pages/Store/CategoriesListPage'));
const StoreCategoryDetailsPage = lazy(() => import('pages/Store/CategoryDetailsPage'));
const StoreVendorsListPage = lazy(() => import('pages/Store/VendorsListPage'));
const StoreItemsListPage = lazy(() => import('pages/Store/ItemsListPage'));

const VendorsListPage = lazy(() => import('pages/Vendor/VendorsListPage'));
const VendorToolsPage = lazy(() => import('pages/Vendor/ToolsPage'));
const VendorDetailsPage = lazy(() => import('pages/Vendor/DetailsPage'));
const VendorCategoriesListPage = lazy(() => import('pages/Vendor/CategoriesListPage'));
const VendorCategoryDetailsPage = lazy(() => import('pages/Vendor/CategoryDetailsPage'));

const BeesVendoSelectionPage = lazy(() => import('pages/BeesVendo/SelectionPage'));
const BeesVendoTransactionHistoryPage = lazy(
  () => import('pages/BeesVendo/TransactionHistoryPage')
);

const BeesForceSearchPage = lazy(() => import('pages/Force/pages/Search'));
const BeesForceAccountBdrPage = lazy(() => import('pages/Force/pages/AccountBDR'));
const BeesForceBdrVisitsPage = lazy(() => import('pages/Force/VisitsListPage'));
const BeesForceVisitDetailsPage = lazy(() => import('pages/Force/VisitDetailsPage'));
const BeesForceTasksListPage = lazy(() => import('pages/Force/TasksListPage'));
const BeesForceTaskDetailsPage = lazy(() => import('pages/Force/TaskDetailsPage'));

const commonRoutes: RouteObject[] = [
  { path: COUNTRY_SELECTION, element: <CountrySelectionPage /> },
  { path: HOME_TOOLS, element: <HomeToolsPage /> },
  { path: '*', element: <Error403Page /> },
  { path: ACCOUNT_TOOLS, element: <AccountToolsPage /> },
  {
    path: BEES_FORCE_SEARCH,
    element: <BeesForceSearchPage />,
  },
  {
    path: BEES_FORCE_ACCOUNT_BDR,
    element: <BeesForceAccountBdrPage />,
  },
  {
    path: BEES_FORCE_BDR_VISITS_LIST,
    element: <BeesForceBdrVisitsPage />,
  },
  { path: BEES_FORCE_BDR_VISIT_DETAILS, element: <BeesForceVisitDetailsPage /> },
  { path: BEES_FORCE_BDR_TASKS_LIST, element: <BeesForceTasksListPage /> },
  {
    path: BEES_FORCE_BDR_TASK_DETAILS,
    element: <BeesForceTaskDetailsPage />,
  },
  {
    path: ACCOUNT_SELECTION_HOME,
    element: <AccountSelectionHomePage />,
    children: [
      { path: ACCOUNT_SELECTION_STEP_ONE, element: <AccountStepOne /> },
      { path: ACCOUNT_SELECTION_STEP_TWO, element: <AccountStepTwo /> },
      { path: CUSTOMER_SELECTION_STEP_ONE, element: <CustomerStepOne /> },
      { path: CUSTOMER_SELECTION_STEP_TWO, element: <CustomerStepTwo /> },
    ],
  },
  { path: ACCOUNT_INVOICES_LIST, element: <AccountInvoicesListPage /> },
  { path: ACCOUNT_INVOICE_DETAILS, element: <AccountInvoiceDetailsPage /> },
  { path: ACCOUNT_ORDERS_LIST, element: <AccountOrdersListPage /> },
  { path: ACCOUNT_ORDER_DETAILS, element: <AccountOrderDetailsPage /> },
  { path: ACCOUNT_CUSTOMERS_LIST, element: <AccountCustomersListPage /> },
  { path: ACCOUNT_CUSTOMER_DETAILS, element: <AccountCustomerDetailsPage /> },
  { path: ACCOUNT_TRUCK_REVIEW, element: <AccountTruckReviewPage /> },
  { path: ACCOUNT_TRUCK_SIMULATION, element: <AccountTruckSimulationPage /> },
  { path: ACCOUNT_PRODUCTS_LIST, element: <AccountProductsListPage /> },
  { path: ACCOUNT_DETAILS, element: <AccountDetailsPage /> },
  { path: ACCOUNT_REWARDS, element: <AccountRewardsPage /> },
  { path: ACCOUNT_REWARD_CHALLENGE_DETAILS, element: <RewardChallengeDetailsPage /> },
  { path: ACCOUNT_ALGO_SELLING, element: <AccountAlgoSellingPage /> },
  { path: STORES_LIST, element: <StoresListPage /> },
  { path: STORE_TOOLS, element: <StoreToolsPage /> },
  { path: STORE_CATEGORIES_LIST, element: <StoreCategoriesListPage /> },
  { path: STORE_CATEGORY_DETAILS, element: <StoreCategoryDetailsPage /> },
  { path: STORE_VENDORS_LIST, element: <StoreVendorsListPage /> },
  { path: STORE_ITEMS_LIST, element: <StoreItemsListPage /> },
  { path: VENDORS_LIST, element: <VendorsListPage /> },
  { path: VENDOR_DETAILS, element: <VendorDetailsPage /> },
  { path: VENDOR_TOOLS, element: <VendorToolsPage /> },
  { path: VENDOR_CATEGORIES_LIST, element: <VendorCategoriesListPage /> },
  { path: VENDOR_CATEGORY_DETAILS, element: <VendorCategoryDetailsPage /> },
  { path: BEES_VENDO_SELECTION, element: <BeesVendoSelectionPage /> },
  { path: BEES_VENDO_TRANSACTIONS, element: <BeesVendoTransactionHistoryPage /> },
  { path: ACCOUNT_DELIVERY_WINDOWS, element: <AccountDeliveryWindowsPage /> },
];

const defaultRoutes: RouteObject[] = [...commonRoutes];

export const routesConfiguration = defaultRoutes.map((props) => ({
  ...props,
  element: <ConnectedWrapper>{props.element}</ConnectedWrapper>,
}));
