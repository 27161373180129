import format from 'date-fns/format';
import { EDateType } from 'hooks/useDataFormatter';

type TDate = number | Date | string | undefined;
export type TDateType = (typeof EDateType)[keyof typeof EDateType];

interface FormattedDateByLocaleProps {
  date: TDate | undefined;
  locale: string | undefined;
  type: TDateType;
}

const config = {
  // 17 Mar 2023
  [EDateType.SHORT]: 'dd MMM yyyy',
  // Jan 5, 2023
  [EDateType.LONG]: 'MMM dd, yyyy',
  // Thu, Mar 16, 2023, 11:59:59 pm GMT
  [EDateType.GMT]: 'iii, MMM dd, yyyy, HH:mm:ss aaa O',
  // Thu, Mar 16, 2023, 11:59 UTC
  [EDateType.GMT24H]: "MMM dd, yyyy, HH:mm 'UTC'",
  // Friday, Dec 16
  [EDateType.WEEK]: 'iiii, MMM dd',
  // 2023-03-17
  [EDateType.ISO]: 'yyyy-MM-dd',
};

const formattedDateByLocale = ({ date, locale, type }: FormattedDateByLocaleProps): string => {
  if (!locale || !date || !type) {
    return '';
  }

  const dateObj = new Date(date);
  const dtDateOnly = new Date(dateObj.valueOf() + dateObj.getTimezoneOffset() * 60 * 1000);
  const formattedString = format(dtDateOnly, config[type]);

  if (type === EDateType.GMT) {
    return formattedString.slice(0, -2);
  }

  return formattedString;
};

export default formattedDateByLocale;
