import { countryList } from 'consts/countryList';
import { useApiaryParams } from 'hooks/useApiaryParams';
import { useEnvContext } from 'hooks/useEnvContext';
import { countryHelper } from 'utils/helpers';

interface IUseCountryFeaturesResponse {
  isMarketplace: boolean;
  isVendo: boolean;
  countryCode: string;
  language: string;
}

export const useCountryFeatures = (): IUseCountryFeaturesResponse => {
  const { multivendorCountries, beesVendoCountries } = useEnvContext();
  const { countryCode } = useApiaryParams();

  const isMarketplace = !countryHelper.isCountryCodeContained({
    countries: multivendorCountries,
    countryCode,
  });

  const isVendo = countryHelper.isCountryCodeContained({
    countries: beesVendoCountries,
    countryCode,
  });

  const language =
    countryList.find((country) => country.code === countryCode)?.languages?.[0]?.locale ?? 'en-US';

  return { isMarketplace, isVendo, countryCode, language };
};
