import { Currency, Store, Store2, TreeDiagram, User } from '@hexa-ui/icons';
import styled, { css } from 'styled-components';

import { SvgBeesForce } from '../Force/components/Icons/BeesForce';

interface IGridProps {
  size: number;
}

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.9);
  font-size: 32px;
  max-width: 400px;
  letter-spacing: 0;
  margin: 0 auto;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
`;

export const Grid = styled.ul<IGridProps>`
  padding: 10px;
  margin: 32px auto 0px;
  display: grid;
  gap: 16px;
  justify-content: center;
  @media screen and (min-width: 663px) {
    width: fit-content;
    grid-template-columns: ${({ size }) => (size === 4 || size === 2 ? '1fr 1fr' : '1fr 1fr 1fr')};
  }
`;

export const GridItem = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const StyledStore = styled(Store)`
  color: #b070ff;
`;

export const StyledUser = styled(User)`
  color: #ff575a;
`;

export const StyledTreeDiagram = styled(TreeDiagram)`
  color: #0084d1;
`;

export const StyledStore2 = styled(Store2)`
  color: #d17a00;
`;

export const StyledCurrency = styled(Currency)`
  color: #00a83b;
`;

interface IBeesForceProps {
  size: 'medium' | 'large';
}

export const StyledBeesForce = styled(SvgBeesForce)<IBeesForceProps>`
  color: #000000;

  ${(props) =>
    props.size === 'medium' &&
    css`
      width: 24px;
      height: 24px;
    `}

  ${(props) =>
    props.size === 'large' &&
    css`
      width: 32px;
      height: 32px;
    `}
`;
