import { Analytics } from '@segment/analytics-node';
import { EnvContext } from 'contexts/EnvProvider';
import { useContext } from 'react';
import segmentAnalytics, { setTypewriterOptions } from '../../analytics';

export const useInstantiateSegmentAnalytics = (segmentKey: string) => {
  if (segmentKey) {
    const analytics = new Analytics({
      writeKey: segmentKey,
      maxEventsInBatch: 1,
    });
    setTypewriterOptions({ analytics });
  }
};

export const useSegmentAnalytics = () => {
  const { segmentKey } = useContext(EnvContext);
  if (segmentKey) return segmentAnalytics;
  return null;
};
