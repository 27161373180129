import { countryList } from 'consts/countryList';

const isCountryMultiLanguage = (countryCode?: string): boolean => {
  const country = countryList.find(({ code }) => code === countryCode);
  if (!country?.languages) return false;

  return country?.languages?.length > 1;
};

export default isCountryMultiLanguage;
