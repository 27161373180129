interface IProps {
  countryCode?: string;
  countries?: string;
}

const isCountryCodeContained = ({ countryCode, countries }: IProps): boolean => {
  if (!countryCode || !countries) return false;
  const countriesList = countries.split(',');

  return !!countriesList.find((code) => code === countryCode);
};

export default isCountryCodeContained;
