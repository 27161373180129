import { LoadingBuzz } from '@hexa-ui/components';
import { formatMessage } from 'i18n/formatters';
import { JSX } from 'react';
import { ContentContainer, LoadingSubtitle, LoadingTitle, SpinnerContainer } from './styles';

interface IBeeSpinnerParams {
  titleMessage?: string;
  subtitleMessage?: string;
  hideSubtitle?: boolean;
}

export const BeeSpinner = ({
  titleMessage,
  subtitleMessage,
  hideSubtitle,
}: IBeeSpinnerParams): JSX.Element => {
  return (
    <SpinnerContainer>
      <ContentContainer data-testid="bee-spinner">
        <LoadingBuzz size="large" />
        <LoadingTitle hidesubtitle={hideSubtitle}>
          {titleMessage ?? formatMessage({ id: 'LOADING.LOADING' })}
        </LoadingTitle>
        {!hideSubtitle && (
          <LoadingSubtitle>
            {subtitleMessage ?? formatMessage({ id: 'LOADING.ALMOST_THERE' })}
          </LoadingSubtitle>
        )}
      </ContentContainer>
    </SpinnerContainer>
  );
};
