import { countryList } from 'consts/countryList';
import { ICountry } from 'interfaces/ICountry';
import { SET_COUNTRY } from '../actionTypes';
import { ISystemReducerAction, SystemStoreInterface } from '../interfaces';

const initialState: SystemStoreInterface = {
  selectedCountry: null,
};

const withFlag = (country: ICountry | null): ICountry | null => {
  if (!country) {
    localStorage.removeItem('countryCode');
    return country;
  }

  localStorage.setItem('countryCode', country?.code);
  if (!country?.flag) {
    return {
      ...country,
      flag: countryList.find(({ code }) => code === country.code)?.flag ?? '🚩',
    };
  }
  return country;
};

export default (state = initialState, action: ISystemReducerAction): SystemStoreInterface => {
  if (action?.type === SET_COUNTRY) {
    const { selectedCountry } = action.payload;
    return {
      ...state,
      selectedCountry: withFlag(selectedCountry),
    };
  }

  return state;
};
