import type { SVGProps } from 'react';

export const SvgBeesForce = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19 8.745v-1.85a.481.481 0 0 0-.226-.408l-2.07-1.293A1.273 1.273 0 0 0 16.03 5H7.97c-.238 0-.472.067-.675.194l-2.07 1.293A.481.481 0 0 0 5 6.895v1.85zm0 1.874v1.69c0 .634-.268 1.34-.698 2.063H5.698C5.268 13.648 5 12.943 5 12.31v-1.69zm-8.53 8.83c-.988-.82-2.285-1.973-3.369-3.203H16.9c-1.084 1.23-2.381 2.384-3.369 3.202a2.398 2.398 0 0 1-3.06 0"
      clipRule="evenodd"
    />
  </svg>
);
