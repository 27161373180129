import { countryList } from 'consts/countryList';

interface IGetClusterUrlParams {
  europeUrl?: string;
  globalUrl?: string;
}

const getClusterUrl = ({ europeUrl, globalUrl }: IGetClusterUrlParams): string | undefined => {
  const europeCountries = localStorage.getItem('europeClusterCountries');
  const europeCountriesArray = europeCountries?.split(',') ?? [];
  const selectedCountry = localStorage.getItem('countryCode');

  const isEurope = countryList.find(
    ({ code }) => code === selectedCountry && europeCountriesArray?.includes(code)
  );
  if (isEurope) {
    return europeUrl;
  }
  return globalUrl;
};

export default getClusterUrl;
