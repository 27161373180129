import { SET_ACCOUNT_SELECTED } from 'redux/actionTypes';
import { ISelectedAccountReducerAction, ISelectedAccountStoreInterface } from 'redux/interfaces';

const initialState: ISelectedAccountStoreInterface = {
  accountSelected: null,
};

export default (
  state = initialState,
  action: ISelectedAccountReducerAction
): ISelectedAccountStoreInterface => {
  if (action.type === SET_ACCOUNT_SELECTED) {
    const { accountSelected } = action.payload;
    return {
      ...state,
      accountSelected,
    };
  }

  return state;
};
