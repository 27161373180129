import MessageMap from '../i18n.d';

const ptBr: MessageMap = {
  CONSTS: {
    YES: 'Sim',
    NO: 'Não',
  },
  GENERAL: {
    APIARY_TOOLS_LINK_LABEL: 'Ferramentas de Monitoramento do aplicativo do varejista',
    NO_APIARY_ACCESS:
      'Sua conta ainda não tem acesso a esta ferramenta. Se você acredita que isso é um engano, entre em contato com o administrador.',

    IOS_RESTRICTED_LABEL: 'iOS restrito',
    DISABLED_LABEL: 'não habilitado',
  },
  COUNTRY_SELECTION: {
    NO_ENVIRONMENT_ALERT: 'Sua conta não tem acesso a nenhum ambiente neste país.',
    BEES_APIARY_TOOLS_LABEL: 'Ferramentas de Monitoramento do aplicativo do varejista',
    BEES_APIARY_TOOLS_CAPTION:
      'Selecione seu país para acessar as ferramentas de monitoramento do aplicativo do varejista',
  },
  ACCOUNT_REWARDS: {
    /* ENROLLMENT_DATE: '',
    BALANCE: '',
    TRANSACTIONS: '',
    CHALLENGES: '', */
    TITLE: 'Pontos',
    NO_ENROLLED_ALERT: 'Usuário não registrado no programa de pontos.',
  },
  VENDOR_FORM: {
    SELECT_VENDOR_WHOLESALER: 'Selecione um fornecedor/atacadista',
    INFO_MESSAGE:
      'Como os IDs das contas dos clientes não são únicos, nós precisamos saber qual fornecedor/atacadista você está usando para encontrar a conta correta no nosso sistema.',
  },
};
export default ptBr;
