import { AnalyticsBrowser } from '@segment/analytics-next';
import { createContext, FC, ReactNode, useMemo } from 'react';

interface ISegmentProviderProps {
  writeKey: string;
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const SegmentContext = createContext<AnalyticsBrowser>(undefined!);

export const SegmentProvider: FC<ISegmentProviderProps> = ({ children, writeKey }) => {
  const analytics = useMemo(() => AnalyticsBrowser.load({ writeKey }), [writeKey]);

  return <SegmentContext.Provider value={analytics}>{children}</SegmentContext.Provider>;
};
