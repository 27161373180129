import CanaryIslandsFlag from 'assets/flags/Canary_Islands.svg';
import NetherlandsAntillesFlag from 'assets/flags/Netherlands_Antilles.svg';
import { ICountry } from 'interfaces/ICountry';

export const countryList: ICountry[] = [
  {
    code: 'AD',
    name: 'Andorra',
    flag: {
      png: 'https://flagcdn.com/w320/ad.png',
      svg: 'https://flagcdn.com/ad.svg',
    },
    languages: [
      {
        code: 'cat',
        name: 'Catalan',
        locale: 'ca-AD',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'AF',
    name: 'Afghanistan',
    flag: {
      png: 'https://flagcdn.com/w320/af.png',
      svg: 'https://flagcdn.com/af.svg',
    },
    languages: [
      {
        code: 'prs',
        name: 'Dari',
        locale: 'fa-AF',
      },
      {
        code: 'pus',
        name: 'Pashto',
        locale: 'ps-AF',
      },
      {
        code: 'tuk',
        name: 'Turkmen',
        locale: 'tk-AF',
      },
    ],
    currencies: [
      {
        code: 'AFN',
        name: 'Afghan afghani',
        symbol: '؋',
      },
    ],
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
    flag: {
      png: 'https://flagcdn.com/w320/ag.png',
      svg: 'https://flagcdn.com/ag.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-AG',
      },
    ],
    currencies: [
      {
        code: 'XCD',
        name: 'Eastern Caribbean dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'AI',
    name: 'Anguilla',
    flag: {
      png: 'https://flagcdn.com/w320/ai.png',
      svg: 'https://flagcdn.com/ai.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-AI',
      },
    ],
    currencies: [
      {
        code: 'XCD',
        name: 'Eastern Caribbean dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'AL',
    name: 'Albania',
    flag: {
      png: 'https://flagcdn.com/w320/al.png',
      svg: 'https://flagcdn.com/al.svg',
    },
    languages: [
      {
        code: 'sqi',
        name: 'Albanian',
        locale: 'sq-AL',
      },
    ],
    currencies: [
      {
        code: 'ALL',
        name: 'Albanian lek',
        symbol: 'L',
      },
    ],
  },
  {
    code: 'AM',
    name: 'Armenia',
    flag: {
      png: 'https://flagcdn.com/w320/am.png',
      svg: 'https://flagcdn.com/am.svg',
    },
    languages: [
      {
        code: 'hye',
        name: 'Armenian',
        locale: 'hy-AM',
      },
    ],
    currencies: [
      {
        code: 'AMD',
        name: 'Armenian dram',
        symbol: '֏',
      },
    ],
  },
  {
    code: 'AO',
    name: 'Angola',
    flag: {
      png: 'https://flagcdn.com/w320/ao.png',
      svg: 'https://flagcdn.com/ao.svg',
    },
    languages: [
      {
        code: 'por',
        name: 'Portuguese',
        locale: 'pt-AO',
      },
    ],
    currencies: [
      {
        code: 'AOA',
        name: 'Angolan kwanza',
        symbol: 'Kz',
      },
    ],
  },
  {
    code: 'AQ',
    name: 'Antarctica',
    flag: {
      png: 'https://flagcdn.com/w320/aq.png',
      svg: 'https://flagcdn.com/aq.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-US',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'AR',
    name: 'Argentina',
    flag: {
      png: 'https://flagcdn.com/w320/ar.png',
      svg: 'https://flagcdn.com/ar.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-AR',
      },
      {
        code: 'zho',
        name: 'Chinese',
        locale: 'zh-CN',
      },
      {
        code: 'grn',
        name: 'Guaraní',
        locale: 'gn-AR',
      },
    ],
    currencies: [
      {
        code: 'ARS',
        name: 'Argentine peso',
        symbol: '$',
      },
    ],
  },
  {
    code: 'AS',
    name: 'American Samoa',
    flag: {
      png: 'https://flagcdn.com/w320/as.png',
      svg: 'https://flagcdn.com/as.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-AS',
      },
      {
        code: 'smo',
        name: 'Samoan',
        locale: 'sm-AS',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'AT',
    name: 'Austria',
    flag: {
      png: 'https://flagcdn.com/w320/at.png',
      svg: 'https://flagcdn.com/at.svg',
    },
    languages: [
      {
        code: 'de',
        name: 'German',
        locale: 'de-AT',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'AU',
    name: 'Australia',
    flag: {
      png: 'https://flagcdn.com/w320/au.png',
      svg: 'https://flagcdn.com/au.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-AU',
      },
    ],
    currencies: [
      {
        code: 'AUD',
        name: 'Australian dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'AW',
    name: 'Aruba',
    flag: {
      png: 'https://flagcdn.com/w320/aw.png',
      svg: 'https://flagcdn.com/aw.svg',
    },
    languages: [
      {
        code: 'nld',
        name: 'Dutch',
        locale: 'nl-AW',
      },
      {
        code: 'pap',
        name: 'Papiamento',
        locale: 'pap-AW',
      },
    ],
    currencies: [
      {
        code: 'AWG',
        name: 'Aruban florin',
        symbol: 'ƒ',
      },
    ],
  },
  {
    code: 'AX',
    name: 'Åland Islands',
    flag: {
      png: 'https://flagcdn.com/w320/ax.png',
      svg: 'https://flagcdn.com/ax.svg',
    },
    languages: [
      {
        code: 'swe',
        name: 'Swedish',
        locale: 'sv-AX',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
    flag: {
      png: 'https://flagcdn.com/w320/az.png',
      svg: 'https://flagcdn.com/az.svg',
    },
    languages: [
      {
        code: 'aze',
        name: 'Azerbaijani',
        locale: 'az-AZ',
      },
      {
        code: 'rus',
        name: 'Russian',
        locale: 'ru-AZ',
      },
    ],
    currencies: [
      {
        code: 'AZN',
        name: 'Azerbaijani manat',
        symbol: '₼',
      },
    ],
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
    flag: {
      png: 'https://flagcdn.com/w320/ba.png',
      svg: 'https://flagcdn.com/ba.svg',
    },
    languages: [
      {
        code: 'bos',
        name: 'Bosnian',
        locale: 'bs-BA',
      },
      {
        code: 'hrv',
        name: 'Croatian',
        locale: 'hr-BA',
      },
      {
        code: 'srp',
        name: 'Serbian',
        locale: 'sr-BA',
      },
    ],
    currencies: [
      {
        code: 'BAM',
        name: 'Bosnia and Herzegovina convertible mark',
        symbol: 'KM',
      },
    ],
  },
  {
    code: 'BB',
    name: 'Barbados',
    flag: {
      png: 'https://flagcdn.com/w320/bb.png',
      svg: 'https://flagcdn.com/bb.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-BB',
      },
    ],
    currencies: [
      {
        code: 'BBD',
        name: 'Barbadian dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'BD',
    name: 'Bangladesh',
    flag: {
      png: 'https://flagcdn.com/w320/bd.png',
      svg: 'https://flagcdn.com/bd.svg',
    },
    languages: [
      {
        code: 'ben',
        name: 'Bengali',
        locale: 'bn-BD',
      },
    ],
    currencies: [
      {
        code: 'BDT',
        name: 'Bangladeshi taka',
        symbol: '৳',
      },
    ],
  },
  {
    code: 'BE',
    name: 'Belgium',
    flag: {
      png: 'https://flagcdn.com/w320/be.png',
      svg: 'https://flagcdn.com/be.svg',
    },
    languages: [
      {
        code: 'nld',
        name: 'Dutch',
        locale: 'nl-BE',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-BE',
      },
      {
        code: 'deu',
        name: 'German',
        locale: 'de-BE',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
    flag: {
      png: 'https://flagcdn.com/w320/bf.png',
      svg: 'https://flagcdn.com/bf.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-BF',
      },
    ],
    currencies: [
      {
        code: 'XOF',
        name: 'West African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'BG',
    name: 'Bulgaria',
    flag: {
      png: 'https://flagcdn.com/w320/bg.png',
      svg: 'https://flagcdn.com/bg.svg',
    },
    languages: [
      {
        code: 'bul',
        name: 'Bulgarian',
        locale: 'bg-BG',
      },
    ],
    currencies: [
      {
        code: 'BGN',
        name: 'Bulgarian lev',
        symbol: 'лв',
      },
    ],
  },
  {
    code: 'BH',
    name: 'Bahrain',
    flag: {
      png: 'https://flagcdn.com/w320/bh.png',
      svg: 'https://flagcdn.com/bh.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-BH',
      },
    ],
    currencies: [
      {
        code: 'BHD',
        name: 'Bahraini dinar',
        symbol: '.د.ب',
      },
    ],
  },
  {
    code: 'BI',
    name: 'Burundi',
    flag: {
      png: 'https://flagcdn.com/w320/bi.png',
      svg: 'https://flagcdn.com/bi.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-BI',
      },
      {
        code: 'run',
        name: 'Kirundi',
        locale: 'rn-BI',
      },
    ],
    currencies: [
      {
        code: 'BIF',
        name: 'Burundian franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'BJ',
    name: 'Benin',
    flag: {
      png: 'https://flagcdn.com/w320/bj.png',
      svg: 'https://flagcdn.com/bj.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-BJ',
      },
    ],
    currencies: [
      {
        code: 'XOF',
        name: 'West African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'BL',
    name: 'Saint Barthélemy',
    flag: {
      png: 'https://flagcdn.com/w320/bl.png',
      svg: 'https://flagcdn.com/bl.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-BL',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'BM',
    name: 'Bermuda',
    flag: {
      png: 'https://flagcdn.com/w320/bm.png',
      svg: 'https://flagcdn.com/bm.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-BM',
      },
    ],
    currencies: [
      {
        code: 'BMD',
        name: 'Bermudian dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
    flag: {
      png: 'https://flagcdn.com/w320/bn.png',
      svg: 'https://flagcdn.com/bn.svg',
    },
    languages: [
      {
        code: 'msa',
        name: 'Malay',
        locale: 'ms-BN',
      },
    ],
    currencies: [
      {
        code: 'BND',
        name: 'Brunei dollar',
        symbol: '$',
      },
      {
        code: 'SGD',
        name: 'Singapore dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'BO',
    name: 'Bolivia',
    flag: {
      png: 'https://flagcdn.com/w320/bo.png',
      svg: 'https://flagcdn.com/bo.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-BO',
      },
      {
        code: 'aym',
        name: 'Aymara',
        locale: 'ay-BO',
      },
      {
        code: 'grn',
        name: 'Guaraní',
        locale: 'gn-BO',
      },
      {
        code: 'que',
        name: 'Quechua',
        locale: 'qu-BO',
      },
    ],
    currencies: [
      {
        code: 'BOB',
        name: 'Bolivian boliviano',
        symbol: 'Bs.',
      },
    ],
  },
  {
    code: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
    flag: {
      png: 'https://flagcdn.com/w320/bq.png',
      svg: 'https://flagcdn.com/bq.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-BQ',
      },
      {
        code: 'nld',
        name: 'Dutch',
        locale: 'nl-BQ',
      },
      {
        code: 'pap',
        name: 'Papiamento',
        locale: 'pap-BQ',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'BR',
    name: 'Brazil',
    flag: {
      png: 'https://flagcdn.com/w320/br.png',
      svg: 'https://flagcdn.com/br.svg',
    },
    languages: [
      {
        code: 'por',
        name: 'Portuguese',
        locale: 'pt-BR',
      },
    ],
    currencies: [
      {
        code: 'BRL',
        name: 'Brazilian real',
        symbol: 'R$',
      },
    ],
  },
  {
    code: 'BT',
    name: 'Bhutan',
    flag: {
      png: 'https://flagcdn.com/w320/bt.png',
      svg: 'https://flagcdn.com/bt.svg',
    },
    languages: [
      {
        code: 'dzo',
        name: 'Dzongkha',
        locale: 'dz-BT',
      },
    ],
    currencies: [
      {
        code: 'BTN',
        name: 'Bhutanese ngultrum',
        symbol: 'Nu.',
      },
      {
        code: 'INR',
        name: 'Indian rupee',
        symbol: '₹',
      },
    ],
  },
  {
    code: 'BV',
    name: 'Bouvet Island',
    flag: {
      png: 'https://flagcdn.com/w320/bv.png',
      svg: 'https://flagcdn.com/bv.svg',
    },
    languages: [
      {
        code: 'nor',
        name: 'Norwegian',
        locale: 'no-BV',
      },
    ],
    currencies: [
      {
        code: 'NOK',
        name: 'Norwegian krone',
        symbol: 'kr',
      },
    ],
  },
  {
    code: 'BW',
    name: 'Botswana',
    flag: {
      png: 'https://flagcdn.com/w320/bw.png',
      svg: 'https://flagcdn.com/bw.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-BW',
      },
      {
        code: 'tsn',
        name: 'Tswana',
        locale: 'tn-BW',
      },
    ],
    currencies: [
      {
        code: 'BWP',
        name: 'Botswana pula',
        symbol: 'P',
      },
    ],
  },
  {
    code: 'BY',
    name: 'Belarus',
    flag: {
      png: 'https://flagcdn.com/w320/by.png',
      svg: 'https://flagcdn.com/by.svg',
    },
    languages: [
      {
        code: 'bel',
        name: 'Belarusian',
        locale: 'be-BY',
      },
      {
        code: 'rus',
        name: 'Russian',
        locale: 'ru-BY',
      },
    ],
    currencies: [
      {
        code: 'BYN',
        name: 'Belarusian ruble',
        symbol: 'Br',
      },
    ],
  },
  {
    code: 'BZ',
    name: 'Belize',
    flag: {
      png: 'https://flagcdn.com/w320/bz.png',
      svg: 'https://flagcdn.com/bz.svg',
    },
    languages: [
      {
        code: 'bjz',
        name: 'Belizean Creole',
        locale: 'bjz-BZ',
      },
      {
        code: 'eng',
        name: 'English',
        locale: 'en-BZ',
      },
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-BZ',
      },
    ],
    currencies: [
      {
        code: 'BZD',
        name: 'Belize dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'CA',
    name: 'Canada',
    flag: {
      png: 'https://flagcdn.com/w320/ca.png',
      svg: 'https://flagcdn.com/ca.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-CA',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-CA',
      },
    ],
    currencies: [
      {
        code: 'CAD',
        name: 'Canadian dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'CH',
    name: 'Switzerland',
    flag: {
      png: 'https://flagcdn.com/w320/ch.png',
      svg: 'https://flagcdn.com/ch.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-CH',
      },
      {
        code: 'gsw',
        name: 'Swiss German',
        locale: 'gsw-CH',
      },
      {
        code: 'ita',
        name: 'Italian',
        locale: 'it-CH',
      },
      {
        code: 'roh',
        name: 'Romansh',
        locale: 'rm-CH',
      },
    ],
    currencies: [
      {
        code: 'CHF',
        name: 'Swiss franc',
        symbol: 'Fr.',
      },
    ],
  },
  {
    code: 'CI',
    name: "Côte d'Ivoire",
    flag: {
      png: 'https://flagcdn.com/w320/ci.png',
      svg: 'https://flagcdn.com/ci.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-CI',
      },
    ],
    currencies: [
      {
        code: 'XOF',
        name: 'West African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'CL',
    name: 'Chile',
    flag: {
      png: 'https://flagcdn.com/w320/cl.png',
      svg: 'https://flagcdn.com/cl.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-CL',
      },
    ],
    currencies: [
      {
        code: 'CLP',
        name: 'Chilean peso',
        symbol: '$',
      },
    ],
  },
  {
    code: 'CM',
    name: 'Cameroon',
    flag: {
      png: 'https://flagcdn.com/w320/cm.png',
      svg: 'https://flagcdn.com/cm.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-CM',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-CM',
      },
    ],
    currencies: [
      {
        code: 'XAF',
        name: 'Central African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'CN',
    name: 'China',
    flag: {
      png: 'https://flagcdn.com/w320/cn.png',
      svg: 'https://flagcdn.com/cn.svg',
    },
    languages: [
      {
        code: 'zho',
        name: 'Chinese',
        locale: 'zh-CN',
      },
    ],
    currencies: [
      {
        code: 'CNY',
        name: 'Chinese yuan',
        symbol: '¥',
      },
    ],
  },
  {
    code: 'CO',
    name: 'Colombia',
    flag: {
      png: 'https://flagcdn.com/w320/co.png',
      svg: 'https://flagcdn.com/co.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-CO',
      },
    ],
    currencies: [
      {
        code: 'COP',
        name: 'Colombian peso',
        symbol: '$',
      },
    ],
  },
  {
    code: 'CR',
    name: 'Costa Rica',
    flag: {
      png: 'https://flagcdn.com/w320/cr.png',
      svg: 'https://flagcdn.com/cr.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-CR',
      },
    ],
    currencies: [
      {
        code: 'CRC',
        name: 'Costa Rican colón',
        symbol: '₡',
      },
    ],
  },
  {
    code: 'CU',
    name: 'Cuba',
    flag: {
      png: 'https://flagcdn.com/w320/cu.png',
      svg: 'https://flagcdn.com/cu.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-CU',
      },
    ],
    currencies: [
      {
        code: 'CUC',
        name: 'Cuban convertible peso',
        symbol: '$',
      },
      {
        code: 'CUP',
        name: 'Cuban peso',
        symbol: '$',
      },
    ],
  },
  {
    code: 'CV',
    name: 'Cabo Verde',
    flag: {
      png: 'https://flagcdn.com/w320/cv.png',
      svg: 'https://flagcdn.com/cv.svg',
    },
    languages: [
      {
        code: 'por',
        name: 'Portuguese',
        locale: 'pt-CV',
      },
    ],
    currencies: [
      {
        code: 'CVE',
        name: 'Cape Verdean escudo',
        symbol: 'Esc',
      },
    ],
  },
  {
    code: 'CW',
    name: 'Curaçao',
    flag: {
      png: 'https://flagcdn.com/w320/cw.png',
      svg: 'https://flagcdn.com/cw.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-CW',
      },
      {
        code: 'nld',
        name: 'Dutch',
        locale: 'nl-CW',
      },
      {
        code: 'pap',
        name: 'Papiamento',
        locale: 'pap-CW',
      },
    ],
    currencies: [
      {
        code: 'ANG',
        name: 'Netherlands Antillean guilder',
        symbol: 'ƒ',
      },
    ],
  },
  {
    code: 'CX',
    name: 'Christmas Island',
    flag: {
      png: 'https://flagcdn.com/w320/cx.png',
      svg: 'https://flagcdn.com/cx.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-CX',
      },
    ],
    currencies: [
      {
        code: 'AUD',
        name: 'Australian dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'CY',
    name: 'Cyprus',
    flag: {
      png: 'https://flagcdn.com/w320/cy.png',
      svg: 'https://flagcdn.com/cy.svg',
    },
    languages: [
      {
        code: 'ell',
        name: 'Greek',
        locale: 'el-CY',
      },
      {
        code: 'tur',
        name: 'Turkish',
        locale: 'tr-CY',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'DE',
    name: 'Germany',
    flag: {
      png: 'https://flagcdn.com/w320/de.png',
      svg: 'https://flagcdn.com/de.svg',
    },
    languages: [
      {
        code: 'deu',
        name: 'German',
        locale: 'de-DE',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'DJ',
    name: 'Djibouti',
    flag: {
      png: 'https://flagcdn.com/w320/dj.png',
      svg: 'https://flagcdn.com/dj.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-DJ',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-DJ',
      },
    ],
    currencies: [
      {
        code: 'DJF',
        name: 'Djiboutian franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'DK',
    name: 'Denmark',
    flag: {
      png: 'https://flagcdn.com/w320/dk.png',
      svg: 'https://flagcdn.com/dk.svg',
    },
    languages: [
      {
        code: 'dan',
        name: 'Danish',
        locale: 'da-DK',
      },
    ],
    currencies: [
      {
        code: 'DKK',
        name: 'Danish krone',
        symbol: 'kr',
      },
    ],
  },
  {
    code: 'DM',
    name: 'Dominica',
    flag: {
      png: 'https://flagcdn.com/w320/dm.png',
      svg: 'https://flagcdn.com/dm.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-DM',
      },
    ],
    currencies: [
      {
        code: 'XCD',
        name: 'Eastern Caribbean dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'DZ',
    name: 'Algeria',
    flag: {
      png: 'https://flagcdn.com/w320/dz.png',
      svg: 'https://flagcdn.com/dz.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-DZ',
      },
    ],
    currencies: [
      {
        code: 'DZD',
        name: 'Algerian dinar',
        symbol: 'د.ج',
      },
    ],
  },
  {
    code: 'EC',
    name: 'Ecuador',
    flag: {
      png: 'https://flagcdn.com/w320/ec.png',
      svg: 'https://flagcdn.com/ec.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-EC',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'EE',
    name: 'Estonia',
    flag: {
      png: 'https://flagcdn.com/w320/ee.png',
      svg: 'https://flagcdn.com/ee.svg',
    },
    languages: [
      {
        code: 'est',
        name: 'Estonian',
        locale: 'et-EE',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'EG',
    name: 'Egypt',
    flag: {
      png: 'https://flagcdn.com/w320/eg.png',
      svg: 'https://flagcdn.com/eg.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-EG',
      },
    ],
    currencies: [
      {
        code: 'EGP',
        name: 'Egyptian pound',
        symbol: '£',
      },
    ],
  },
  {
    code: 'EH',
    name: 'Western Sahara',
    flag: {
      png: 'https://flagcdn.com/w320/eh.png',
      svg: 'https://flagcdn.com/eh.svg',
    },
    languages: [
      {
        code: 'ber',
        name: 'Berber',
        locale: 'ber-EH',
      },
      {
        code: 'mey',
        name: 'Hassaniya',
        locale: 'mey-EH',
      },
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-EH',
      },
    ],
    currencies: [
      {
        code: 'DZD',
        name: 'Algerian dinar',
        symbol: 'دج',
      },
      {
        code: 'MAD',
        name: 'Moroccan dirham',
        symbol: 'DH',
      },
      {
        code: 'MRU',
        name: 'Mauritanian ouguiya',
        symbol: 'UM',
      },
    ],
  },
  {
    code: 'ER',
    name: 'Eritrea',
    flag: {
      png: 'https://flagcdn.com/w320/er.png',
      svg: 'https://flagcdn.com/er.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-ER',
      },
      {
        code: 'eng',
        name: 'English',
        locale: 'en-ER',
      },
      {
        code: 'tir',
        name: 'Tigrinya',
        locale: 'ti-ER',
      },
    ],
    currencies: [
      {
        code: 'ERN',
        name: 'Eritrean nakfa',
        symbol: 'Nfk',
      },
    ],
  },
  {
    code: 'ES',
    name: 'Canary Islands',
    flag: {
      png: undefined,
      svg: CanaryIslandsFlag,
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-ES',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'ET',
    name: 'Ethiopia',
    flag: {
      png: 'https://flagcdn.com/w320/et.png',
      svg: 'https://flagcdn.com/et.svg',
    },
    languages: [
      {
        code: 'amh',
        name: 'Amharic',
        locale: 'am-ET',
      },
    ],
    currencies: [
      {
        code: 'ETB',
        name: 'Ethiopian birr',
        symbol: 'Br',
      },
    ],
  },
  {
    code: 'FI',
    name: 'Finland',
    flag: {
      png: 'https://flagcdn.com/w320/fi.png',
      svg: 'https://flagcdn.com/fi.svg',
    },
    languages: [
      {
        code: 'fin',
        name: 'Finnish',
        locale: 'fi-FI',
      },
      {
        code: 'swe',
        name: 'Swedish',
        locale: 'sv-FI',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'FJ',
    name: 'Fiji',
    flag: {
      png: 'https://flagcdn.com/w320/fj.png',
      svg: 'https://flagcdn.com/fj.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-FJ',
      },
      {
        code: 'fij',
        name: 'Fijian',
        locale: 'fj-FJ',
      },
      {
        code: 'hif',
        name: 'Fiji Hindi',
        locale: 'hif-FJ',
      },
    ],
    currencies: [
      {
        code: 'FJD',
        name: 'Fijian dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'FM',
    name: 'Micronesia (Federated States of)',
    flag: {
      png: 'https://flagcdn.com/w320/fm.png',
      svg: 'https://flagcdn.com/fm.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-FM',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'FR',
    name: 'France',
    flag: {
      png: 'https://flagcdn.com/w320/fr.png',
      svg: 'https://flagcdn.com/fr.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-FR',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'GA',
    name: 'Gabon',
    flag: {
      png: 'https://flagcdn.com/w320/ga.png',
      svg: 'https://flagcdn.com/ga.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-GA',
      },
    ],
    currencies: [
      {
        code: 'XAF',
        name: 'Central African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'GD',
    name: 'Grenada',
    flag: {
      png: 'https://flagcdn.com/w320/gd.png',
      svg: 'https://flagcdn.com/gd.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-GD',
      },
    ],
    currencies: [
      {
        code: 'XCD',
        name: 'Eastern Caribbean dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'GE',
    name: 'Georgia',
    flag: {
      png: 'https://flagcdn.com/w320/ge.png',
      svg: 'https://flagcdn.com/ge.svg',
    },
    languages: [
      {
        code: 'kat',
        name: 'Georgian',
        locale: 'ka-GE',
      },
    ],
    currencies: [
      {
        code: 'GEL',
        name: 'lari',
        symbol: '₾',
      },
    ],
  },
  {
    code: 'GF',
    name: 'French Guiana',
    flag: {
      png: 'https://flagcdn.com/w320/gf.png',
      svg: 'https://flagcdn.com/gf.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-GF',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'GG',
    name: 'Guernsey',
    flag: {
      png: 'https://flagcdn.com/w320/gg.png',
      svg: 'https://flagcdn.com/gg.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-GG',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-GG',
      },
      {
        code: 'nfr',
        name: 'Guernésiais',
        locale: 'nfr-GG',
      },
    ],
    currencies: [
      {
        code: 'GBP',
        name: 'British pound',
        symbol: '£',
      },
      {
        code: 'GGP',
        name: 'Guernsey pound',
        symbol: '£',
      },
    ],
  },
  {
    code: 'GH',
    name: 'Ghana',
    flag: {
      png: 'https://flagcdn.com/w320/gh.png',
      svg: 'https://flagcdn.com/gh.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-GH',
      },
    ],
    currencies: [
      {
        code: 'GHS',
        name: 'Ghanaian cedi',
        symbol: '₵',
      },
    ],
  },
  {
    code: 'GI',
    name: 'Gibraltar',
    flag: {
      png: 'https://flagcdn.com/w320/gi.png',
      svg: 'https://flagcdn.com/gi.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-GI',
      },
    ],
    currencies: [
      {
        code: 'GIP',
        name: 'Gibraltar pound',
        symbol: '£',
      },
    ],
  },
  {
    code: 'GL',
    name: 'Greenland',
    flag: {
      png: 'https://flagcdn.com/w320/gl.png',
      svg: 'https://flagcdn.com/gl.svg',
    },
    languages: [
      {
        code: 'kal',
        name: 'Greenlandic',
        locale: 'kl-GL',
      },
    ],
    currencies: [
      {
        code: 'DKK',
        name: 'krone',
        symbol: 'kr.',
      },
    ],
  },
  {
    code: 'GN',
    name: 'Guinea',
    flag: {
      png: 'https://flagcdn.com/w320/gn.png',
      svg: 'https://flagcdn.com/gn.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-GN',
      },
    ],
    currencies: [
      {
        code: 'GNF',
        name: 'Guinean franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
    flag: {
      png: 'https://flagcdn.com/w320/gp.png',
      svg: 'https://flagcdn.com/gp.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-GP',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
    flag: {
      png: 'https://flagcdn.com/w320/gq.png',
      svg: 'https://flagcdn.com/gq.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-GQ',
      },
      {
        code: 'por',
        name: 'Portuguese',
        locale: 'pt-GQ',
      },
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-GQ',
      },
    ],
    currencies: [
      {
        code: 'XAF',
        name: 'Central African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'GR',
    name: 'Greece',
    flag: {
      png: 'https://flagcdn.com/w320/gr.png',
      svg: 'https://flagcdn.com/gr.svg',
    },
    languages: [
      {
        code: 'ell',
        name: 'Greek',
        locale: 'el-GR',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
    flag: {
      png: 'https://flagcdn.com/w320/gs.png',
      svg: 'https://flagcdn.com/gs.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-GS',
      },
    ],
    currencies: [
      {
        code: 'SHP',
        name: 'Saint Helena pound',
        symbol: '£',
      },
    ],
  },
  {
    code: 'GT',
    name: 'Guatemala',
    flag: {
      png: 'https://flagcdn.com/w320/gt.png',
      svg: 'https://flagcdn.com/gt.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-GT',
      },
    ],
    currencies: [
      {
        code: 'GTQ',
        name: 'Guatemalan quetzal',
        symbol: 'Q',
      },
    ],
  },
  {
    code: 'GU',
    name: 'Guam',
    flag: {
      png: 'https://flagcdn.com/w320/gu.png',
      svg: 'https://flagcdn.com/gu.svg',
    },
    languages: [
      {
        code: 'cha',
        name: 'Chamorro',
        locale: 'ch-GU',
      },
      {
        code: 'eng',
        name: 'English',
        locale: 'en-GU',
      },
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-GU',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
    flag: {
      png: 'https://flagcdn.com/w320/gw.png',
      svg: 'https://flagcdn.com/gw.svg',
    },
    languages: [
      {
        code: 'por',
        name: 'Portuguese',
        locale: 'pt-GW',
      },
      {
        code: 'pov',
        name: 'Upper Guinea Creole',
        locale: 'pov-GW',
      },
    ],
    currencies: [
      {
        code: 'XOF',
        name: 'West African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'GY',
    name: 'Guyana',
    flag: {
      png: 'https://flagcdn.com/w320/gy.png',
      svg: 'https://flagcdn.com/gy.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-GY',
      },
    ],
    currencies: [
      {
        code: 'GYD',
        name: 'Guyanese dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'HK',
    name: 'Hong Kong',
    flag: {
      png: 'https://flagcdn.com/w320/hk.png',
      svg: 'https://flagcdn.com/hk.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-HK',
      },
      {
        code: 'zho',
        name: 'Chinese',
        locale: 'zh-HK',
      },
    ],
    currencies: [
      {
        code: 'HKD',
        name: 'Hong Kong dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'HN',
    name: 'Honduras',
    flag: {
      png: 'https://flagcdn.com/w320/hn.png',
      svg: 'https://flagcdn.com/hn.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-HN',
      },
    ],
    currencies: [
      {
        code: 'HNL',
        name: 'Honduran lempira',
        symbol: 'L',
      },
    ],
  },
  {
    code: 'HR',
    name: 'Croatia',
    flag: {
      png: 'https://flagcdn.com/w320/hr.png',
      svg: 'https://flagcdn.com/hr.svg',
    },
    languages: [
      {
        code: 'hrv',
        name: 'Croatian',
        locale: 'hr-HR',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'HT',
    name: 'Haiti',
    flag: {
      png: 'https://flagcdn.com/w320/ht.png',
      svg: 'https://flagcdn.com/ht.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-HT',
      },
      {
        code: 'hat',
        name: 'Haitian Creole',
        locale: 'ht-HT',
      },
    ],
    currencies: [
      {
        code: 'HTG',
        name: 'Haitian gourde',
        symbol: 'G',
      },
    ],
  },
  {
    code: 'HU',
    name: 'Hungary',
    flag: {
      png: 'https://flagcdn.com/w320/hu.png',
      svg: 'https://flagcdn.com/hu.svg',
    },
    languages: [
      {
        code: 'hun',
        name: 'Hungarian',
        locale: 'hu-HU',
      },
    ],
    currencies: [
      {
        code: 'HUF',
        name: 'Hungarian forint',
        symbol: 'Ft',
      },
    ],
  },
  {
    code: 'ID',
    name: 'Indonesia',
    flag: {
      png: 'https://flagcdn.com/w320/id.png',
      svg: 'https://flagcdn.com/id.svg',
    },
    languages: [
      {
        code: 'ind',
        name: 'Indonesian',
        locale: 'id-ID',
      },
    ],
    currencies: [
      {
        code: 'IDR',
        name: 'Indonesian rupiah',
        symbol: 'Rp',
      },
    ],
  },
  {
    code: 'IE',
    name: 'Ireland',
    flag: {
      png: 'https://flagcdn.com/w320/ie.png',
      svg: 'https://flagcdn.com/ie.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-IE',
      },
      {
        code: 'gle',
        name: 'Irish',
        locale: 'ga-IE',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'IL',
    name: 'Israel',
    flag: {
      png: 'https://flagcdn.com/w320/il.png',
      svg: 'https://flagcdn.com/il.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-IL',
      },
      {
        code: 'heb',
        name: 'Hebrew',
        locale: 'he-IL',
      },
    ],
    currencies: [
      {
        code: 'ILS',
        name: 'Israeli new shekel',
        symbol: '₪',
      },
    ],
  },
  {
    code: 'IM',
    name: 'Isle of Man',
    flag: {
      png: 'https://flagcdn.com/w320/im.png',
      svg: 'https://flagcdn.com/im.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-IM',
      },
      {
        code: 'glv',
        name: 'Manx',
        locale: 'gv-IM',
      },
    ],
    currencies: [
      {
        code: 'GBP',
        name: 'British pound',
        symbol: '£',
      },
      {
        code: 'IMP',
        name: 'Manx pound',
        symbol: '£',
      },
    ],
  },
  {
    code: 'IN',
    name: 'India',
    flag: {
      png: 'https://flagcdn.com/w320/in.png',
      svg: 'https://flagcdn.com/in.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-IN',
      },
      {
        code: 'hin',
        name: 'Hindi',
        locale: 'hi-IN',
      },
      {
        code: 'tam',
        name: 'Tamil',
        locale: 'ta-IN',
      },
    ],
    currencies: [
      {
        code: 'INR',
        name: 'Indian rupee',
        symbol: '₹',
      },
    ],
  },
  {
    code: 'IQ',
    name: 'Iraq',
    flag: {
      png: 'https://flagcdn.com/w320/iq.png',
      svg: 'https://flagcdn.com/iq.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-IQ',
      },
      {
        code: 'arc',
        name: 'Aramaic',
        locale: 'arc-IQ',
      },
      {
        code: 'ckb',
        name: 'Sorani',
        locale: 'ckb-IQ',
      },
    ],
    currencies: [
      {
        code: 'IQD',
        name: 'Iraqi dinar',
        symbol: 'ع.د',
      },
    ],
  },
  {
    code: 'IR',
    name: 'Iran (Islamic Republic of)',
    flag: {
      png: 'https://flagcdn.com/w320/ir.png',
      svg: 'https://flagcdn.com/ir.svg',
    },
    languages: [
      {
        code: 'fas',
        name: 'Persian (Farsi)',
        locale: 'fa-IR',
      },
    ],
    currencies: [
      {
        code: 'IRR',
        name: 'Iranian rial',
        symbol: '﷼',
      },
    ],
  },
  {
    code: 'IS',
    name: 'Iceland',
    flag: {
      png: 'https://flagcdn.com/w320/is.png',
      svg: 'https://flagcdn.com/is.svg',
    },
    languages: [
      {
        code: 'isl',
        name: 'Icelandic',
        locale: 'is-IS',
      },
    ],
    currencies: [
      {
        code: 'ISK',
        name: 'Icelandic króna',
        symbol: 'kr',
      },
    ],
  },
  {
    code: 'IT',
    name: 'Italy',
    flag: {
      png: 'https://flagcdn.com/w320/it.png',
      svg: 'https://flagcdn.com/it.svg',
    },
    languages: [
      {
        code: 'ita',
        name: 'Italian',
        locale: 'it-IT',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'JE',
    name: 'Jersey',
    flag: {
      png: 'https://flagcdn.com/w320/je.png',
      svg: 'https://flagcdn.com/je.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-JE',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-JE',
      },
      {
        code: 'nrf',
        name: 'Jèrriais',
        locale: 'nrf-JE',
      },
    ],
    currencies: [
      {
        code: 'GBP',
        name: 'British pound',
        symbol: '£',
      },
      {
        code: 'JEP',
        name: 'Jersey pound',
        symbol: '£',
      },
    ],
  },
  {
    code: 'JM',
    name: 'Jamaica',
    flag: {
      png: 'https://flagcdn.com/w320/jm.png',
      svg: 'https://flagcdn.com/jm.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-JM',
      },
      {
        code: 'jam',
        name: 'Jamaican Patois',
        locale: 'jam-JM',
      },
    ],
    currencies: [
      {
        code: 'JMD',
        name: 'Jamaican dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'JO',
    name: 'Jordan',
    flag: {
      png: 'https://flagcdn.com/w320/jo.png',
      svg: 'https://flagcdn.com/jo.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-JO',
      },
    ],
    currencies: [
      {
        code: 'JOD',
        name: 'Jordanian dinar',
        symbol: 'د.ا',
      },
    ],
  },
  {
    code: 'JP',
    name: 'Japan',
    flag: {
      png: 'https://flagcdn.com/w320/jp.png',
      svg: 'https://flagcdn.com/jp.svg',
    },
    languages: [
      {
        code: 'jpn',
        name: 'Japanese',
        locale: 'ja-JP',
      },
    ],
    currencies: [
      {
        code: 'JPY',
        name: 'Japanese yen',
        symbol: '¥',
      },
    ],
  },
  {
    code: 'KE',
    name: 'Kenya',
    flag: {
      png: 'https://flagcdn.com/w320/ke.png',
      svg: 'https://flagcdn.com/ke.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-KE',
      },
      {
        code: 'swa',
        name: 'Swahili',
        locale: 'sw-KE',
      },
    ],
    currencies: [
      {
        code: 'KES',
        name: 'Kenyan shilling',
        symbol: 'Sh',
      },
    ],
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
    flag: {
      png: 'https://flagcdn.com/w320/kg.png',
      svg: 'https://flagcdn.com/kg.svg',
    },
    languages: [
      {
        code: 'kir',
        name: 'Kyrgyz',
        locale: 'ky-KG',
      },
      {
        code: 'rus',
        name: 'Russian',
        locale: 'ru-KG',
      },
    ],
    currencies: [
      {
        code: 'KGS',
        name: 'Kyrgyzstani som',
        symbol: 'с',
      },
    ],
  },
  {
    code: 'KH',
    name: 'Cambodia',
    flag: {
      png: 'https://flagcdn.com/w320/kh.png',
      svg: 'https://flagcdn.com/kh.svg',
    },
    languages: [
      {
        code: 'khm',
        name: 'Khmer',
        locale: 'km-KH',
      },
    ],
    currencies: [
      {
        code: 'KHR',
        name: 'Cambodian riel',
        symbol: '៛',
      },
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'KP',
    name: 'North Korea',
    flag: {
      png: 'https://flagcdn.com/w320/kp.png',
      svg: 'https://flagcdn.com/kp.svg',
    },
    languages: [
      {
        code: 'kor',
        name: 'Korean',
        locale: 'ko-KP',
      },
    ],
    currencies: [
      {
        code: 'KPW',
        name: 'North Korean won',
        symbol: '₩',
      },
    ],
  },
  {
    code: 'KR',
    name: 'South Korea',
    flag: {
      png: 'https://flagcdn.com/w320/kr.png',
      svg: 'https://flagcdn.com/kr.svg',
    },
    languages: [
      {
        code: 'kor',
        name: 'Korean',
        locale: 'ko-KR',
      },
    ],
    currencies: [
      {
        code: 'KRW',
        name: 'South Korean won',
        symbol: '₩',
      },
    ],
  },
  {
    code: 'KI',
    name: 'Kiribati',
    flag: {
      png: 'https://flagcdn.com/w320/ki.png',
      svg: 'https://flagcdn.com/ki.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-KI',
      },
      {
        code: 'gil',
        name: 'Gilbertese',
        locale: 'gil-KI',
      },
    ],
    currencies: [
      {
        code: 'AUD',
        name: 'Australian dollar',
        symbol: '$',
      },
      {
        code: 'KID',
        name: 'Kiribati dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
    flag: {
      png: 'https://flagcdn.com/w320/kn.png',
      svg: 'https://flagcdn.com/kn.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-KN',
      },
    ],
    currencies: [
      {
        code: 'XCD',
        name: 'Eastern Caribbean dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'KW',
    name: 'Kuwait',
    flag: {
      png: 'https://flagcdn.com/w320/kw.png',
      svg: 'https://flagcdn.com/kw.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-KW',
      },
    ],
    currencies: [
      {
        code: 'KWD',
        name: 'Kuwaiti dinar',
        symbol: 'د.ك',
      },
    ],
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
    flag: {
      png: 'https://flagcdn.com/w320/kz.png',
      svg: 'https://flagcdn.com/kz.svg',
    },
    languages: [
      {
        code: 'kaz',
        name: 'Kazakh',
        locale: 'kk-KZ',
      },
      {
        code: 'rus',
        name: 'Russian',
        locale: 'ru-KZ',
      },
    ],
    currencies: [
      {
        code: 'KZT',
        name: 'Kazakhstani tenge',
        symbol: '₸',
      },
    ],
  },
  {
    code: 'LB',
    name: 'Lebanon',
    flag: {
      png: 'https://flagcdn.com/w320/lb.png',
      svg: 'https://flagcdn.com/lb.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-LB',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-LB',
      },
    ],
    currencies: [
      {
        code: 'LBP',
        name: 'Lebanese pound',
        symbol: 'ل.ل',
      },
    ],
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
    flag: {
      png: 'https://flagcdn.com/w320/lc.png',
      svg: 'https://flagcdn.com/lc.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-LC',
      },
    ],
    currencies: [
      {
        code: 'XCD',
        name: 'Eastern Caribbean dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
    flag: {
      png: 'https://flagcdn.com/w320/li.png',
      svg: 'https://flagcdn.com/li.svg',
    },
    languages: [
      {
        code: 'deu',
        name: 'German',
        locale: 'de-LI',
      },
    ],
    currencies: [
      {
        code: 'CHF',
        name: 'Swiss franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
    flag: {
      png: 'https://flagcdn.com/w320/lk.png',
      svg: 'https://flagcdn.com/lk.svg',
    },
    languages: [
      {
        code: 'sin',
        name: 'Sinhala',
        locale: 'si-LK',
      },
      {
        code: 'tam',
        name: 'Tamil',
        locale: 'ta-LK',
      },
    ],
    currencies: [
      {
        code: 'LKR',
        name: 'Sri Lankan rupee',
        symbol: 'Rs  රු',
      },
    ],
  },
  {
    code: 'LR',
    name: 'Liberia',
    flag: {
      png: 'https://flagcdn.com/w320/lr.png',
      svg: 'https://flagcdn.com/lr.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-LR',
      },
    ],
    currencies: [
      {
        code: 'LRD',
        name: 'Liberian dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'LS',
    name: 'Lesotho',
    flag: {
      png: 'https://flagcdn.com/w320/ls.png',
      svg: 'https://flagcdn.com/ls.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-LS',
      },
      {
        code: 'sot',
        name: 'Sotho',
        locale: 'st-LS',
      },
    ],
    currencies: [
      {
        code: 'LSL',
        name: 'Lesotho loti',
        symbol: 'L',
      },
      {
        code: 'ZAR',
        name: 'South African rand',
        symbol: 'R',
      },
    ],
  },
  {
    code: 'LT',
    name: 'Lithuania',
    flag: {
      png: 'https://flagcdn.com/w320/lt.png',
      svg: 'https://flagcdn.com/lt.svg',
    },
    languages: [
      {
        code: 'lit',
        name: 'Lithuanian',
        locale: 'lt-LT',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    flag: {
      png: 'https://flagcdn.com/w320/lu.png',
      svg: 'https://flagcdn.com/lu.svg',
    },
    languages: [
      {
        code: 'deu',
        name: 'German',
        locale: 'de-LU',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-LU',
      },
      {
        code: 'ltz',
        name: 'Luxembourgish',
        locale: 'lb-LU',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'LV',
    name: 'Latvia',
    flag: {
      png: 'https://flagcdn.com/w320/lv.png',
      svg: 'https://flagcdn.com/lv.svg',
    },
    languages: [
      {
        code: 'lav',
        name: 'Latvian',
        locale: 'lv-LV',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'LY',
    name: 'Libya',
    flag: {
      png: 'https://flagcdn.com/w320/ly.png',
      svg: 'https://flagcdn.com/ly.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-LY',
      },
    ],
    currencies: [
      {
        code: 'LYD',
        name: 'Libyan dinar',
        symbol: 'ل.د',
      },
    ],
  },
  {
    code: 'MA',
    name: 'Morocco',
    flag: {
      png: 'https://flagcdn.com/w320/ma.png',
      svg: 'https://flagcdn.com/ma.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-MA',
      },
      {
        code: 'ber',
        name: 'Berber',
        locale: 'ber-MA',
      },
    ],
    currencies: [
      {
        code: 'MAD',
        name: 'Moroccan dirham',
        symbol: 'د.م.',
      },
    ],
  },
  {
    code: 'MC',
    name: 'Monaco',
    flag: {
      png: 'https://flagcdn.com/w320/mc.png',
      svg: 'https://flagcdn.com/mc.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-MC',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'ME',
    name: 'Montenegro',
    flag: {
      png: 'https://flagcdn.com/w320/me.png',
      svg: 'https://flagcdn.com/me.svg',
    },
    languages: [
      {
        code: 'cnr',
        name: 'Montenegrin',
        locale: 'sr-ME',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'MF',
    name: 'Saint Martin (French part)',
    flag: {
      png: 'https://flagcdn.com/w320/mf.png',
      svg: 'https://flagcdn.com/mf.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-MF',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'MG',
    name: 'Madagascar',
    flag: {
      png: 'https://flagcdn.com/w320/mg.png',
      svg: 'https://flagcdn.com/mg.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-MG',
      },
      {
        code: 'mlg',
        name: 'Malagasy',
        locale: 'mg-MG',
      },
    ],
    currencies: [
      {
        code: 'MGA',
        name: 'Malagasy ariary',
        symbol: 'Ar',
      },
    ],
  },
  {
    code: 'ML',
    name: 'Mali',
    flag: {
      png: 'https://flagcdn.com/w320/ml.png',
      svg: 'https://flagcdn.com/ml.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-ML',
      },
    ],
    currencies: [
      {
        code: 'XOF',
        name: 'West African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'MM',
    name: 'Myanmar',
    flag: {
      png: 'https://flagcdn.com/w320/mm.png',
      svg: 'https://flagcdn.com/mm.svg',
    },
    languages: [
      {
        code: 'mya',
        name: 'Burmese',
        locale: 'my-MM',
      },
    ],
    currencies: [
      {
        code: 'MMK',
        name: 'Burmese kyat',
        symbol: 'Ks',
      },
    ],
  },
  {
    code: 'MN',
    name: 'Mongolia',
    flag: {
      png: 'https://flagcdn.com/w320/mn.png',
      svg: 'https://flagcdn.com/mn.svg',
    },
    languages: [
      {
        code: 'mon',
        name: 'Mongolian',
        locale: 'mn-MN',
      },
    ],
    currencies: [
      {
        code: 'MNT',
        name: 'Mongolian tögrög',
        symbol: '₮',
      },
    ],
  },
  {
    code: 'MO',
    name: 'Macao',
    flag: {
      png: 'https://flagcdn.com/w320/mo.png',
      svg: 'https://flagcdn.com/mo.svg',
    },
    languages: [
      {
        code: 'por',
        name: 'Portuguese',
        locale: 'pt-MO',
      },
      {
        code: 'zho',
        name: 'Chinese',
        locale: 'zh-MO',
      },
    ],
    currencies: [
      {
        code: 'MOP',
        name: 'Macanese pataca',
        symbol: 'P',
      },
    ],
  },
  {
    code: 'MQ',
    name: 'Martinique',
    flag: {
      png: 'https://flagcdn.com/w320/mq.png',
      svg: 'https://flagcdn.com/mq.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-MQ',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'MR',
    name: 'Mauritania',
    flag: {
      png: 'https://flagcdn.com/w320/mr.png',
      svg: 'https://flagcdn.com/mr.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-MR',
      },
    ],
    currencies: [
      {
        code: 'MRU',
        name: 'Mauritanian ouguiya',
        symbol: 'UM',
      },
    ],
  },
  {
    code: 'MS',
    name: 'Montserrat',
    flag: {
      png: 'https://flagcdn.com/w320/ms.png',
      svg: 'https://flagcdn.com/ms.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-MS',
      },
    ],
    currencies: [
      {
        code: 'XCD',
        name: 'Eastern Caribbean dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'MT',
    name: 'Malta',
    flag: {
      png: 'https://flagcdn.com/w320/mt.png',
      svg: 'https://flagcdn.com/mt.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-MT',
      },
      {
        code: 'mlt',
        name: 'Maltese',
        locale: 'mt-MT',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'MU',
    name: 'Mauritius',
    flag: {
      png: 'https://flagcdn.com/w320/mu.png',
      svg: 'https://flagcdn.com/mu.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-MU',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-MU',
      },
      {
        code: 'mfe',
        name: 'Mauritian Creole',
        locale: 'mfe-MU',
      },
    ],
    currencies: [
      {
        code: 'MUR',
        name: 'Mauritian rupee',
        symbol: '₨',
      },
    ],
  },
  {
    code: 'MV',
    name: 'Maldives',
    flag: {
      png: 'https://flagcdn.com/w320/mv.png',
      svg: 'https://flagcdn.com/mv.svg',
    },
    languages: [
      {
        code: 'div',
        name: 'Maldivian',
        locale: 'dv-MV',
      },
    ],
    currencies: [
      {
        code: 'MVR',
        name: 'Maldivian rufiyaa',
        symbol: '.ރ',
      },
    ],
  },
  {
    code: 'MW',
    name: 'Malawi',
    flag: {
      png: 'https://flagcdn.com/w320/mw.png',
      svg: 'https://flagcdn.com/mw.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-MW',
      },
      {
        code: 'nya',
        name: 'Chewa',
        locale: 'ny-MW',
      },
    ],
    currencies: [
      {
        code: 'MWK',
        name: 'Malawian kwacha',
        symbol: 'MK',
      },
    ],
  },
  {
    code: 'MX',
    name: 'Mexico',
    flag: {
      png: 'https://flagcdn.com/w320/mx.png',
      svg: 'https://flagcdn.com/mx.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-MX',
      },
    ],
    currencies: [
      {
        code: 'MXN',
        name: 'Mexican peso',
        symbol: '$',
      },
    ],
  },
  {
    code: 'MY',
    name: 'Malaysia',
    flag: {
      png: 'https://flagcdn.com/w320/my.png',
      svg: 'https://flagcdn.com/my.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-MY',
      },
      {
        code: 'msa',
        name: 'Malay',
        locale: 'ms-MY',
      },
    ],
    currencies: [
      {
        code: 'MYR',
        name: 'Malaysian ringgit',
        symbol: 'RM',
      },
    ],
  },
  {
    code: 'MZ',
    name: 'Mozambique',
    flag: {
      png: 'https://flagcdn.com/w320/mz.png',
      svg: 'https://flagcdn.com/mz.svg',
    },
    languages: [
      {
        code: 'por',
        name: 'Portuguese',
        locale: 'pt-MZ',
      },
    ],
    currencies: [
      {
        code: 'MZN',
        name: 'Mozambican metical',
        symbol: 'MT',
      },
    ],
  },
  {
    code: 'NA',
    name: 'Namibia',
    flag: {
      png: 'https://flagcdn.com/w320/na.png',
      svg: 'https://flagcdn.com/na.svg',
    },
    languages: [
      {
        code: 'afr',
        name: 'Afrikaans',
        locale: 'af-NA',
      },
      {
        code: 'deu',
        name: 'German',
        locale: 'de-NA',
      },
      {
        code: 'eng',
        name: 'English',
        locale: 'en-NA',
      },
      {
        code: 'her',
        name: 'Herero',
        locale: 'hz-NA',
      },
      {
        code: 'hgm',
        name: 'Khoekhoe',
        locale: 'hgm-NA',
      },
      {
        code: 'kwn',
        name: 'Kwangali',
        locale: 'kwn-NA',
      },
      {
        code: 'loz',
        name: 'Lozi',
        locale: 'loz-NA',
      },
      {
        code: 'ndo',
        name: 'Ndonga',
        locale: 'ng-NA',
      },
      {
        code: 'tsn',
        name: 'Tswana',
        locale: 'tn-NA',
      },
    ],
    currencies: [
      {
        code: 'NAD',
        name: 'Namibian dollar',
        symbol: '$',
      },
      {
        code: 'ZAR',
        name: 'South African rand',
        symbol: 'R',
      },
    ],
  },
  {
    code: 'NC',
    name: 'New Caledonia',
    flag: {
      png: 'https://flagcdn.com/w320/nc.png',
      svg: 'https://flagcdn.com/nc.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-NC',
      },
    ],
    currencies: [
      {
        code: 'XPF',
        name: 'CFP franc',
        symbol: '₣',
      },
    ],
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
    flag: {
      png: 'https://flagcdn.com/w320/nf.png',
      svg: 'https://flagcdn.com/nf.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-NF',
      },
      {
        code: 'pih',
        name: 'Norfuk',
        locale: 'pih-NF',
      },
    ],
    currencies: [
      {
        code: 'AUD',
        name: 'Australian dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'NG',
    name: 'Nigeria',
    flag: {
      png: 'https://flagcdn.com/w320/ng.png',
      svg: 'https://flagcdn.com/ng.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-NG',
      },
    ],
    currencies: [
      {
        code: 'NGN',
        name: 'Nigerian naira',
        symbol: '₦',
      },
    ],
  },
  {
    code: 'NI',
    name: 'Nicaragua',
    flag: {
      png: 'https://flagcdn.com/w320/ni.png',
      svg: 'https://flagcdn.com/ni.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-NI',
      },
    ],
    currencies: [
      {
        code: 'NIO',
        name: 'Nicaraguan córdoba',
        symbol: 'C$',
      },
    ],
  },
  {
    code: 'NO',
    name: 'Norway',
    flag: {
      png: 'https://flagcdn.com/w320/no.png',
      svg: 'https://flagcdn.com/no.svg',
    },
    languages: [
      {
        code: 'nno',
        name: 'Norwegian Nynorsk',
        locale: 'nn-NO',
      },
      {
        code: 'nob',
        name: 'Norwegian Bokmål',
        locale: 'nb-NO',
      },
      {
        code: 'smi',
        name: 'Sami',
        locale: 'smi-NO',
      },
    ],
    currencies: [
      {
        code: 'NOK',
        name: 'Norwegian krone',
        symbol: 'kr',
      },
    ],
  },
  {
    code: 'NP',
    name: 'Nepal',
    flag: {
      png: 'https://flagcdn.com/w320/np.png',
      svg: 'https://flagcdn.com/np.svg',
    },
    languages: [
      {
        code: 'nep',
        name: 'Nepali',
        locale: 'ne-NP',
      },
    ],
    currencies: [
      {
        code: 'NPR',
        name: 'Nepalese rupee',
        symbol: '₨',
      },
    ],
  },
  {
    code: 'NR',
    name: 'Nauru',
    flag: {
      png: 'https://flagcdn.com/w320/nr.png',
      svg: 'https://flagcdn.com/nr.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-NR',
      },
      {
        code: 'nau',
        name: 'Nauru',
        locale: 'na-NR',
      },
    ],
    currencies: [
      {
        code: 'AUD',
        name: 'Australian dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'NU',
    name: 'Niue',
    flag: {
      png: 'https://flagcdn.com/w320/nu.png',
      svg: 'https://flagcdn.com/nu.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-NU',
      },
      {
        code: 'niu',
        name: 'Niuean',
        locale: 'niu-NU',
      },
    ],
    currencies: [
      {
        code: 'NZD',
        name: 'New Zealand dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    flag: {
      png: 'https://flagcdn.com/w320/nz.png',
      svg: 'https://flagcdn.com/nz.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-NZ',
      },
      {
        code: 'mri',
        name: 'Māori',
        locale: 'mi-NZ',
      },
      {
        code: 'nzs',
        name: 'New Zealand Sign Language',
        locale: 'nzs-NZ',
      },
    ],
    currencies: [
      {
        code: 'NZD',
        name: 'New Zealand dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'OM',
    name: 'Oman',
    flag: {
      png: 'https://flagcdn.com/w320/om.png',
      svg: 'https://flagcdn.com/om.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-OM',
      },
    ],
    currencies: [
      {
        code: 'OMR',
        name: 'Omani rial',
        symbol: 'ر.ع.',
      },
    ],
  },
  {
    code: 'PA',
    name: 'Panama',
    flag: {
      png: 'https://flagcdn.com/w320/pa.png',
      svg: 'https://flagcdn.com/pa.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-PA',
      },
    ],
    currencies: [
      {
        code: 'PAB',
        name: 'Panamanian balboa',
        symbol: 'B/.',
      },
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'PE',
    name: 'Peru',
    flag: {
      png: 'https://flagcdn.com/w320/pe.png',
      svg: 'https://flagcdn.com/pe.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-PE',
      },
      {
        code: 'aym',
        name: 'Aymara',
        locale: 'ay-PE',
      },
      {
        code: 'que',
        name: 'Quechua',
        locale: 'qu-PE',
      },
    ],
    currencies: [
      {
        code: 'PEN',
        name: 'Peruvian sol',
        symbol: 'S/ ',
      },
    ],
  },
  {
    code: 'PF',
    name: 'French Polynesia',
    flag: {
      png: 'https://flagcdn.com/w320/pf.png',
      svg: 'https://flagcdn.com/pf.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-PF',
      },
    ],
    currencies: [
      {
        code: 'XPF',
        name: 'CFP franc',
        symbol: '₣',
      },
    ],
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
    flag: {
      png: 'https://flagcdn.com/w320/pg.png',
      svg: 'https://flagcdn.com/pg.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-PG',
      },
      {
        code: 'hmo',
        name: 'Hiri Motu',
        locale: 'ho-PG',
      },
      {
        code: 'tpi',
        name: 'Tok Pisin',
        locale: 'tpi-PG',
      },
    ],
    currencies: [
      {
        code: 'PGK',
        name: 'Papua New Guinean kina',
        symbol: 'K',
      },
    ],
  },
  {
    code: 'PK',
    name: 'Pakistan',
    flag: {
      png: 'https://flagcdn.com/w320/pk.png',
      svg: 'https://flagcdn.com/pk.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-PK',
      },
      {
        code: 'urd',
        name: 'Urdu',
        locale: 'ur-PK',
      },
    ],
    currencies: [
      {
        code: 'PKR',
        name: 'Pakistani rupee',
        symbol: '₨',
      },
    ],
  },
  {
    code: 'PL',
    name: 'Poland',
    flag: {
      png: 'https://flagcdn.com/w320/pl.png',
      svg: 'https://flagcdn.com/pl.svg',
    },
    languages: [
      {
        code: 'pol',
        name: 'Polish',
        locale: 'pl-PL',
      },
    ],
    currencies: [
      {
        code: 'PLN',
        name: 'Polish złoty',
        symbol: 'zł',
      },
    ],
  },
  {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
    flag: {
      png: 'https://flagcdn.com/w320/pm.png',
      svg: 'https://flagcdn.com/pm.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-PM',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'PN',
    name: 'Pitcairn',
    flag: {
      png: 'https://flagcdn.com/w320/pn.png',
      svg: 'https://flagcdn.com/pn.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-PN',
      },
    ],
    currencies: [
      {
        code: 'NZD',
        name: 'New Zealand dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
    flag: {
      png: 'https://flagcdn.com/w320/pr.png',
      svg: 'https://flagcdn.com/pr.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-PR',
      },
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-PR',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'PS',
    name: 'Palestine, State of',
    flag: {
      png: 'https://flagcdn.com/w320/ps.png',
      svg: 'https://flagcdn.com/ps.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-PS',
      },
    ],
    currencies: [
      {
        code: 'EGP',
        name: 'Egyptian pound',
        symbol: 'E£',
      },
      {
        code: 'ILS',
        name: 'Israeli new shekel',
        symbol: '₪',
      },
      {
        code: 'JOD',
        name: 'Jordanian dinar',
        symbol: 'JD',
      },
    ],
  },
  {
    code: 'PT',
    name: 'Portugal',
    flag: {
      png: 'https://flagcdn.com/w320/pt.png',
      svg: 'https://flagcdn.com/pt.svg',
    },
    languages: [
      {
        code: 'por',
        name: 'Portuguese',
        locale: 'pt-PT',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'PW',
    name: 'Palau',
    flag: {
      png: 'https://flagcdn.com/w320/pw.png',
      svg: 'https://flagcdn.com/pw.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-PW',
      },
      {
        code: 'pau',
        name: 'Palauan',
        locale: 'pau-PW',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'PY',
    name: 'Paraguay',
    flag: {
      png: 'https://flagcdn.com/w320/py.png',
      svg: 'https://flagcdn.com/py.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-PY',
      },
      {
        code: 'grn',
        name: 'Guaraní',
        locale: 'gn-PY',
      },
    ],
    currencies: [
      {
        code: 'PYG',
        name: 'Paraguayan guaraní',
        symbol: '₲',
      },
    ],
  },
  {
    code: 'QA',
    name: 'Qatar',
    flag: {
      png: 'https://flagcdn.com/w320/qa.png',
      svg: 'https://flagcdn.com/qa.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-QA',
      },
    ],
    currencies: [
      {
        code: 'QAR',
        name: 'Qatari riyal',
        symbol: 'ر.ق',
      },
    ],
  },
  {
    code: 'RE',
    name: 'Réunion',
    flag: {
      png: 'https://flagcdn.com/w320/re.png',
      svg: 'https://flagcdn.com/re.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-RE',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'RO',
    name: 'Romania',
    flag: {
      png: 'https://flagcdn.com/w320/ro.png',
      svg: 'https://flagcdn.com/ro.svg',
    },
    languages: [
      {
        code: 'ron',
        name: 'Romanian',
        locale: 'ro-RO',
      },
    ],
    currencies: [
      {
        code: 'RON',
        name: 'Romanian leu',
        symbol: 'lei',
      },
    ],
  },
  {
    code: 'RS',
    name: 'Serbia',
    flag: {
      png: 'https://flagcdn.com/w320/rs.png',
      svg: 'https://flagcdn.com/rs.svg',
    },
    languages: [
      {
        code: 'srp',
        name: 'Serbian',
        locale: 'sr-RS',
      },
    ],
    currencies: [
      {
        code: 'RSD',
        name: 'Serbian dinar',
        symbol: 'дин.',
      },
    ],
  },
  {
    code: 'RU',
    name: 'Russia',
    flag: {
      png: 'https://flagcdn.com/w320/ru.png',
      svg: 'https://flagcdn.com/ru.svg',
    },
    languages: [
      {
        code: 'rus',
        name: 'Russian',
        locale: 'ru-RU',
      },
    ],
    currencies: [
      {
        code: 'RUB',
        name: 'Russian ruble',
        symbol: '₽',
      },
    ],
  },
  {
    code: 'RW',
    name: 'Rwanda',
    flag: {
      png: 'https://flagcdn.com/w320/rw.png',
      svg: 'https://flagcdn.com/rw.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-RW',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-RW',
      },
      {
        code: 'kin',
        name: 'Kinyarwanda',
        locale: 'rw-RW',
      },
    ],
    currencies: [
      {
        code: 'RWF',
        name: 'Rwandan franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    flag: {
      png: 'https://flagcdn.com/w320/sa.png',
      svg: 'https://flagcdn.com/sa.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-SA',
      },
    ],
    currencies: [
      {
        code: 'SAR',
        name: 'Saudi riyal',
        symbol: 'ر.س',
      },
    ],
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
    flag: {
      png: 'https://flagcdn.com/w320/sb.png',
      svg: 'https://flagcdn.com/sb.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-SB',
      },
    ],
    currencies: [
      {
        code: 'SBD',
        name: 'Solomon Islands dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'SC',
    name: 'Seychelles',
    flag: {
      png: 'https://flagcdn.com/w320/sc.png',
      svg: 'https://flagcdn.com/sc.svg',
    },
    languages: [
      {
        code: 'crs',
        name: 'Seychellois Creole',
        locale: 'crs-SC',
      },
      {
        code: 'eng',
        name: 'English',
        locale: 'en-SC',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-SC',
      },
    ],
    currencies: [
      {
        code: 'SCR',
        name: 'Seychellois rupee',
        symbol: '₨',
      },
    ],
  },
  {
    code: 'SE',
    name: 'Sweden',
    flag: {
      png: 'https://flagcdn.com/w320/se.png',
      svg: 'https://flagcdn.com/se.svg',
    },
    languages: [
      {
        code: 'swe',
        name: 'Swedish',
        locale: 'sv-SE',
      },
    ],
    currencies: [
      {
        code: 'SEK',
        name: 'Swedish krona',
        symbol: 'kr',
      },
    ],
  },
  {
    code: 'SG',
    name: 'Singapore',
    flag: {
      png: 'https://flagcdn.com/w320/sg.png',
      svg: 'https://flagcdn.com/sg.svg',
    },
    languages: [
      {
        code: 'zho',
        name: 'Chinese',
        locale: 'zh-SG',
      },
      {
        code: 'eng',
        name: 'English',
        locale: 'en-SG',
      },
      {
        code: 'msa',
        name: 'Malay',
        locale: 'ms-SG',
      },
      {
        code: 'tam',
        name: 'Tamil',
        locale: 'ta-SG',
      },
    ],
    currencies: [
      {
        code: 'SGD',
        name: 'Singapore dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    flag: {
      png: 'https://flagcdn.com/w320/sh.png',
      svg: 'https://flagcdn.com/sh.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-SH',
      },
    ],
    currencies: [
      {
        code: 'GBP',
        name: 'Pound sterling',
        symbol: '£',
      },
      {
        code: 'SHP',
        name: 'Saint Helena pound',
        symbol: '£',
      },
    ],
  },
  {
    code: 'SI',
    name: 'Slovenia',
    flag: {
      png: 'https://flagcdn.com/w320/si.png',
      svg: 'https://flagcdn.com/si.svg',
    },
    languages: [
      {
        code: 'slv',
        name: 'Slovene',
        locale: 'sl-SI',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'SJ',
    name: 'Svalbard and Jan Mayen',
    flag: {
      png: 'https://flagcdn.com/w320/sj.png',
      svg: 'https://flagcdn.com/sj.svg',
    },
    languages: [
      {
        code: 'nor',
        name: 'Norwegian',
        locale: 'no-SJ',
      },
    ],
    currencies: [
      {
        code: 'NOK',
        name: 'krone',
        symbol: 'kr',
      },
    ],
  },
  {
    code: 'SK',
    name: 'Slovakia',
    flag: {
      png: 'https://flagcdn.com/w320/sk.png',
      svg: 'https://flagcdn.com/sk.svg',
    },
    languages: [
      {
        code: 'slk',
        name: 'Slovak',
        locale: 'sk-SK',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
    flag: {
      png: 'https://flagcdn.com/w320/sl.png',
      svg: 'https://flagcdn.com/sl.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-SL',
      },
    ],
    currencies: [
      {
        code: 'SLL',
        name: 'Sierra Leonean leone',
        symbol: 'Le',
      },
    ],
  },
  {
    code: 'SM',
    name: 'Republic of San Marino',
    flag: {
      png: 'https://flagcdn.com/w320/sm.png',
      svg: 'https://flagcdn.com/sm.svg',
    },
    languages: [
      {
        code: 'ita',
        name: 'Italian',
        locale: 'it-SM',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'SN',
    name: 'Senegal',
    flag: {
      png: 'https://flagcdn.com/w320/sn.png',
      svg: 'https://flagcdn.com/sn.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-SN',
      },
    ],
    currencies: [
      {
        code: 'XOF',
        name: 'West African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'SO',
    name: 'Somalia',
    flag: {
      png: 'https://flagcdn.com/w320/so.png',
      svg: 'https://flagcdn.com/so.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-SO',
      },
      {
        code: 'som',
        name: 'Somali',
        locale: 'so-SO',
      },
    ],
    currencies: [
      {
        code: 'SOS',
        name: 'Somali shilling',
        symbol: 'Sh',
      },
    ],
  },
  {
    code: 'SR',
    name: 'Suriname',
    flag: {
      png: 'https://flagcdn.com/w320/sr.png',
      svg: 'https://flagcdn.com/sr.svg',
    },
    languages: [
      {
        code: 'nld',
        name: 'Dutch',
        locale: 'nl-SR',
      },
    ],
    currencies: [
      {
        code: 'SRD',
        name: 'Surinamese dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'SS',
    name: 'South Sudan',
    flag: {
      png: 'https://flagcdn.com/w320/ss.png',
      svg: 'https://flagcdn.com/ss.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-SS',
      },
    ],
    currencies: [
      {
        code: 'SSP',
        name: 'South Sudanese pound',
        symbol: '£',
      },
    ],
  },
  {
    code: 'ST',
    name: 'Sao Tome and Principe',
    flag: {
      png: 'https://flagcdn.com/w320/st.png',
      svg: 'https://flagcdn.com/st.svg',
    },
    languages: [
      {
        code: 'por',
        name: 'Portuguese',
        locale: 'pt-ST',
      },
    ],
    currencies: [
      {
        code: 'STN',
        name: 'São Tomé and Príncipe dobra',
        symbol: 'Db',
      },
    ],
  },
  {
    code: 'SV',
    name: 'El Salvador',
    flag: {
      png: 'https://flagcdn.com/w320/sv.png',
      svg: 'https://flagcdn.com/sv.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-SV',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'SX',
    name: 'Sint Maarten (Dutch part)',
    flag: {
      png: 'https://flagcdn.com/w320/sx.png',
      svg: 'https://flagcdn.com/sx.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-SX',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-SX',
      },
      {
        code: 'nld',
        name: 'Dutch',
        locale: 'nl-SX',
      },
    ],
    currencies: [
      {
        code: 'ANG',
        name: 'Netherlands Antillean guilder',
        symbol: 'ƒ',
      },
    ],
  },
  {
    code: 'SY',
    name: 'Syrian Arab Republic',
    flag: {
      png: 'https://flagcdn.com/w320/sy.png',
      svg: 'https://flagcdn.com/sy.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-SY',
      },
    ],
    currencies: [
      {
        code: 'SYP',
        name: 'Syrian pound',
        symbol: '£',
      },
    ],
  },
  {
    code: 'TD',
    name: 'Chad',
    flag: {
      png: 'https://flagcdn.com/w320/td.png',
      svg: 'https://flagcdn.com/td.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-TD',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-TD',
      },
    ],
    currencies: [
      {
        code: 'XAF',
        name: 'Central African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'TG',
    name: 'Togo',
    flag: {
      png: 'https://flagcdn.com/w320/tg.png',
      svg: 'https://flagcdn.com/tg.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-TG',
      },
    ],
    currencies: [
      {
        code: 'XOF',
        name: 'West African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'TH',
    name: 'Thailand',
    flag: {
      png: 'https://flagcdn.com/w320/th.png',
      svg: 'https://flagcdn.com/th.svg',
    },
    languages: [
      {
        code: 'tha',
        name: 'Thai',
        locale: 'th-TH',
      },
    ],
    currencies: [
      {
        code: 'THB',
        name: 'Thai baht',
        symbol: '฿',
      },
    ],
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
    flag: {
      png: 'https://flagcdn.com/w320/tj.png',
      svg: 'https://flagcdn.com/tj.svg',
    },
    languages: [
      {
        code: 'rus',
        name: 'Russian',
        locale: 'ru-TJ',
      },
      {
        code: 'tgk',
        name: 'Tajik',
        locale: 'tg-TJ',
      },
    ],
    currencies: [
      {
        code: 'TJS',
        name: 'Tajikistani somoni',
        symbol: 'ЅМ',
      },
    ],
  },
  {
    code: 'TK',
    name: 'Tokelau',
    flag: {
      png: 'https://flagcdn.com/w320/tk.png',
      svg: 'https://flagcdn.com/tk.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-TK',
      },
      {
        code: 'smo',
        name: 'Samoan',
        locale: 'sm-TK',
      },
      {
        code: 'tkl',
        name: 'Tokelauan',
        locale: 'tkl-TK',
      },
    ],
    currencies: [
      {
        code: 'NZD',
        name: 'New Zealand dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'TL',
    name: 'Timor-Leste',
    flag: {
      png: 'https://flagcdn.com/w320/tl.png',
      svg: 'https://flagcdn.com/tl.svg',
    },
    languages: [
      {
        code: 'por',
        name: 'Portuguese',
        locale: 'pt-TL',
      },
      {
        code: 'tet',
        name: 'Tetum',
        locale: 'tet-TL',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
    flag: {
      png: 'https://flagcdn.com/w320/tm.png',
      svg: 'https://flagcdn.com/tm.svg',
    },
    languages: [
      {
        code: 'rus',
        name: 'Russian',
        locale: 'ru-TM',
      },
      {
        code: 'tuk',
        name: 'Turkmen',
        locale: 'tk-TM',
      },
    ],
    currencies: [
      {
        code: 'TMT',
        name: 'Turkmenistan manat',
        symbol: 'm',
      },
    ],
  },
  {
    code: 'TN',
    name: 'Tunisia',
    flag: {
      png: 'https://flagcdn.com/w320/tn.png',
      svg: 'https://flagcdn.com/tn.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-TN',
      },
    ],
    currencies: [
      {
        code: 'TND',
        name: 'Tunisian dinar',
        symbol: 'د.ت',
      },
    ],
  },
  {
    code: 'TO',
    name: 'Tonga',
    flag: {
      png: 'https://flagcdn.com/w320/to.png',
      svg: 'https://flagcdn.com/to.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-TO',
      },
      {
        code: 'ton',
        name: 'Tongan',
        locale: 'to-TO',
      },
    ],
    currencies: [
      {
        code: 'TOP',
        name: 'Tongan paʻanga',
        symbol: 'T$',
      },
    ],
  },
  {
    code: 'TR',
    name: 'Turkey',
    flag: {
      png: 'https://flagcdn.com/w320/tr.png',
      svg: 'https://flagcdn.com/tr.svg',
    },
    languages: [
      {
        code: 'tur',
        name: 'Turkish',
        locale: 'tr-TR',
      },
    ],
    currencies: [
      {
        code: 'TRY',
        name: 'Turkish lira',
        symbol: '₺',
      },
    ],
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
    flag: {
      png: 'https://flagcdn.com/w320/tt.png',
      svg: 'https://flagcdn.com/tt.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-TT',
      },
    ],
    currencies: [
      {
        code: 'TTD',
        name: 'Trinidad and Tobago dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'TV',
    name: 'Tuvalu',
    flag: {
      png: 'https://flagcdn.com/w320/tv.png',
      svg: 'https://flagcdn.com/tv.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-TV',
      },
      {
        code: 'tvl',
        name: 'Tuvaluan',
        locale: 'tvl-TV',
      },
    ],
    currencies: [
      {
        code: 'AUD',
        name: 'Australian dollar',
        symbol: '$',
      },
      {
        code: 'TVD',
        name: 'Tuvaluan dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'TZ',
    name: 'United Republic of Tanzania',
    flag: {
      png: 'https://flagcdn.com/w320/tz.png',
      svg: 'https://flagcdn.com/tz.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-TZ',
      },
      {
        code: 'swa',
        name: 'Swahili',
        locale: 'sw-TZ',
      },
    ],
    currencies: [
      {
        code: 'TZS',
        name: 'Tanzanian shilling',
        symbol: 'Sh',
      },
    ],
  },
  {
    code: 'UA',
    name: 'Ukraine',
    flag: {
      png: 'https://flagcdn.com/w320/ua.png',
      svg: 'https://flagcdn.com/ua.svg',
    },
    languages: [
      {
        code: 'ukr',
        name: 'Ukrainian',
        locale: 'uk-UA',
      },
    ],
    currencies: [
      {
        code: 'UAH',
        name: 'Ukrainian hryvnia',
        symbol: '₴',
      },
    ],
  },
  {
    code: 'UG',
    name: 'Uganda',
    flag: {
      png: 'https://flagcdn.com/w320/ug.png',
      svg: 'https://flagcdn.com/ug.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-UG',
      },
      {
        code: 'swa',
        name: 'Swahili',
        locale: 'sw-UG',
      },
    ],
    currencies: [
      {
        code: 'UGX',
        name: 'Ugandan shilling',
        symbol: 'Sh',
      },
    ],
  },
  {
    code: 'US',
    name: 'United States of America',
    flag: {
      png: 'https://flagcdn.com/w320/us.png',
      svg: 'https://flagcdn.com/us.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-US',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'UY',
    name: 'Uruguay',
    flag: {
      png: 'https://flagcdn.com/w320/uy.png',
      svg: 'https://flagcdn.com/uy.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-UY',
      },
    ],
    currencies: [
      {
        code: 'UYU',
        name: 'Uruguayan peso',
        symbol: '$',
      },
    ],
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    flag: {
      png: 'https://flagcdn.com/w320/uz.png',
      svg: 'https://flagcdn.com/uz.svg',
    },
    languages: [
      {
        code: 'rus',
        name: 'Russian',
        locale: 'ru-UZ',
      },
      {
        code: 'uzb',
        name: 'Uzbek',
        locale: 'uz-UZ',
      },
    ],
    currencies: [
      {
        code: 'UZS',
        name: 'Uzbekistani soʻm',
        symbol: "so'm",
      },
    ],
  },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
    flag: {
      png: 'https://flagcdn.com/w320/vc.png',
      svg: 'https://flagcdn.com/vc.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-VC',
      },
    ],
    currencies: [
      {
        code: 'XCD',
        name: 'Eastern Caribbean dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'VE',
    name: 'Venezuela (Bolivarian Republic of)',
    flag: {
      png: 'https://flagcdn.com/w320/ve.png',
      svg: 'https://flagcdn.com/ve.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-VE',
      },
    ],
    currencies: [
      {
        code: 'VES',
        name: 'Venezuelan bolívar soberano',
        symbol: 'Bs.S.',
      },
    ],
  },
  {
    code: 'VG',
    name: 'Virgin Islands (British)',
    flag: {
      png: 'https://flagcdn.com/w320/vg.png',
      svg: 'https://flagcdn.com/vg.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-VG',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'VI',
    name: 'Virgin Islands (U.S.)',
    flag: {
      png: 'https://flagcdn.com/w320/vi.png',
      svg: 'https://flagcdn.com/vi.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-VI',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'VN',
    name: 'Vietnam',
    flag: {
      png: 'https://flagcdn.com/w320/vn.png',
      svg: 'https://flagcdn.com/vn.svg',
    },
    languages: [
      {
        code: 'vie',
        name: 'Vietnamese',
        locale: 'vi-VN',
      },
    ],
    currencies: [
      {
        code: 'VND',
        name: 'Vietnamese đồng',
        symbol: '₫',
      },
    ],
  },
  {
    code: 'VU',
    name: 'Vanuatu',
    flag: {
      png: 'https://flagcdn.com/w320/vu.png',
      svg: 'https://flagcdn.com/vu.svg',
    },
    languages: [
      {
        code: 'bis',
        name: 'Bislama',
        locale: 'bi-VU',
      },
      {
        code: 'eng',
        name: 'English',
        locale: 'en-VU',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-VU',
      },
    ],
    currencies: [
      {
        code: 'VUV',
        name: 'Vanuatu vatu',
        symbol: 'Vt',
      },
    ],
  },
  {
    code: 'WF',
    name: 'Wallis and Futuna',
    flag: {
      png: 'https://flagcdn.com/w320/wf.png',
      svg: 'https://flagcdn.com/wf.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-WF',
      },
    ],
    currencies: [
      {
        code: 'XPF',
        name: 'CFP franc',
        symbol: '₣',
      },
    ],
  },
  {
    code: 'WS',
    name: 'Samoa',
    flag: {
      png: 'https://flagcdn.com/w320/ws.png',
      svg: 'https://flagcdn.com/ws.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-WS',
      },
      {
        code: 'smo',
        name: 'Samoan',
        locale: 'sm-WS',
      },
    ],
    currencies: [
      {
        code: 'WST',
        name: 'Samoan tālā',
        symbol: 'T',
      },
    ],
  },
  {
    code: 'YE',
    name: 'Yemen',
    flag: {
      png: 'https://flagcdn.com/w320/ye.png',
      svg: 'https://flagcdn.com/ye.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-YE',
      },
    ],
    currencies: [
      {
        code: 'YER',
        name: 'Yemeni rial',
        symbol: '﷼',
      },
    ],
  },
  {
    code: 'YT',
    name: 'Mayotte',
    flag: {
      png: 'https://flagcdn.com/w320/yt.png',
      svg: 'https://flagcdn.com/yt.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-YT',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'ZA',
    name: 'South Africa',
    flag: {
      png: 'https://flagcdn.com/w320/za.png',
      svg: 'https://flagcdn.com/za.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-ZA',
      },
      {
        code: 'afr',
        name: 'Afrikaans',
        locale: 'af-ZA',
      },
      {
        code: 'nbl',
        name: 'Southern Ndebele',
        locale: 'nr-ZA',
      },
      {
        code: 'nso',
        name: 'Northern Sotho',
        locale: 'nso-ZA',
      },
      {
        code: 'sot',
        name: 'Southern Sotho',
        locale: 'st-ZA',
      },
      {
        code: 'ssw',
        name: 'Swazi',
        locale: 'ss-ZA',
      },
      {
        code: 'tsn',
        name: 'Tswana',
        locale: 'tn-ZA',
      },
      {
        code: 'tso',
        name: 'Tsonga',
        locale: 'ts-ZA',
      },
      {
        code: 'ven',
        name: 'Venda',
        locale: 've-ZA',
      },
      {
        code: 'xho',
        name: 'Xhosa',
        locale: 'xh-ZA',
      },
      {
        code: 'zul',
        name: 'Zulu',
        locale: 'zu-ZA',
      },
    ],
    currencies: [
      {
        code: 'ZAR',
        name: 'South African rand',
        symbol: 'R',
      },
    ],
  },
  {
    code: 'ZM',
    name: 'Zambia',
    flag: {
      png: 'https://flagcdn.com/w320/zm.png',
      svg: 'https://flagcdn.com/zm.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-ZM',
      },
    ],
    currencies: [
      {
        code: 'ZMW',
        name: 'Zambian kwacha',
        symbol: 'ZK',
      },
    ],
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
    flag: {
      png: 'https://flagcdn.com/w320/zw.png',
      svg: 'https://flagcdn.com/zw.svg',
    },
    languages: [
      {
        code: 'bwg',
        name: 'Chibarwe',
        locale: 'bwg-ZW',
      },
      {
        code: 'eng',
        name: 'English',
        locale: 'en-ZW',
      },
      {
        code: 'kck',
        name: 'Kalanga',
        locale: 'kck-ZW',
      },
      {
        code: 'khi',
        name: 'Khoisan',
        locale: 'khi-ZW',
      },
      {
        code: 'ndc',
        name: 'Ndau',
        locale: 'ndc-ZW',
      },
      {
        code: 'nde',
        name: 'Northern Ndebele',
        locale: 'nd-ZW',
      },
      {
        code: 'nya',
        name: 'Chewa',
        locale: 'ny-ZW',
      },
      {
        code: 'sna',
        name: 'Shona',
        locale: 'sn-ZW',
      },
      {
        code: 'sot',
        name: 'Sotho',
        locale: 'st-ZW',
      },
      {
        code: 'toi',
        name: 'Tonga',
        locale: 'toi-ZW',
      },
      {
        code: 'tsn',
        name: 'Tswana',
        locale: 'tn-ZW',
      },
      {
        code: 'tso',
        name: 'Tsonga',
        locale: 'ts-ZW',
      },
      {
        code: 'ven',
        name: 'Venda',
        locale: 've-ZW',
      },
      {
        code: 'xho',
        name: 'Xhosa',
        locale: 'xh-ZW',
      },
      {
        code: 'zib',
        name: 'Zimbabwean Sign Language',
        locale: 'zib-ZW',
      },
    ],
    currencies: [
      {
        code: 'ZWL',
        name: 'Zimbabwean dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'SZ',
    name: 'Eswatini',
    flag: {
      png: 'https://flagcdn.com/w320/sz.png',
      svg: 'https://flagcdn.com/sz.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-SZ',
      },
      {
        code: 'ssw',
        name: 'Swazi',
        locale: 'ss-SZ',
      },
    ],
    currencies: [
      {
        code: 'SZL',
        name: 'Swazi lilangeni',
        symbol: 'L',
      },
      {
        code: 'ZAR',
        name: 'South African rand',
        symbol: 'R',
      },
    ],
  },
  {
    code: 'MK',
    name: 'North Macedonia',
    flag: {
      png: 'https://flagcdn.com/w320/mk.png',
      svg: 'https://flagcdn.com/mk.svg',
    },
    languages: [
      {
        code: 'mkd',
        name: 'Macedonian',
        locale: 'mk-MK',
      },
    ],
    currencies: [
      {
        code: 'MKD',
        name: 'denar',
        symbol: 'den',
      },
    ],
  },
  {
    code: 'PH',
    name: 'Philippines',
    flag: {
      png: 'https://flagcdn.com/w320/ph.png',
      svg: 'https://flagcdn.com/ph.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-PH',
      },
      {
        code: 'fil',
        name: 'Filipino',
        locale: 'fil-PH',
      },
    ],
    currencies: [
      {
        code: 'PHP',
        name: 'Philippine peso',
        symbol: '₱',
      },
    ],
  },
  {
    code: 'NL',
    name: 'Netherlands',
    flag: {
      png: 'https://flagcdn.com/w320/nl.png',
      svg: 'https://flagcdn.com/nl.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-NL',
      },
      {
        code: 'nld',
        name: 'Dutch',
        locale: 'nl-NL',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    flag: {
      png: 'https://flagcdn.com/w320/ae.png',
      svg: 'https://flagcdn.com/ae.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-AE',
      },
    ],
    currencies: [
      {
        code: 'AED',
        name: 'United Arab Emirates dirham',
        symbol: 'د.إ',
      },
    ],
  },
  {
    code: 'MD',
    name: 'Republic of Moldova',
    flag: {
      png: 'https://flagcdn.com/w320/md.png',
      svg: 'https://flagcdn.com/md.svg',
    },
    languages: [
      {
        code: 'ron',
        name: 'Romanian',
        locale: 'ro-MD',
      },
    ],
    currencies: [
      {
        code: 'MDL',
        name: 'Moldovan leu',
        symbol: 'L',
      },
    ],
  },
  {
    code: 'GM',
    name: 'Gambia',
    flag: {
      png: 'https://flagcdn.com/w320/gm.png',
      svg: 'https://flagcdn.com/gm.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-GM',
      },
    ],
    currencies: [
      {
        code: 'GMD',
        name: 'dalasi',
        symbol: 'D',
      },
    ],
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
    flag: {
      png: 'https://flagcdn.com/w320/do.png',
      svg: 'https://flagcdn.com/do.svg',
    },
    languages: [
      {
        code: 'spa',
        name: 'Spanish',
        locale: 'es-DO',
      },
    ],
    currencies: [
      {
        code: 'DOP',
        name: 'Dominican peso',
        symbol: '$',
      },
    ],
  },
  {
    code: 'SD',
    name: 'Sudan',
    flag: {
      png: 'https://flagcdn.com/w320/sd.png',
      svg: 'https://flagcdn.com/sd.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-SD',
      },
      {
        code: 'eng',
        name: 'English',
        locale: 'en-SD',
      },
    ],
    currencies: [
      {
        code: 'SDG',
        name: 'Sudanese pound',
        symbol: 'LS',
      },
    ],
  },
  {
    code: 'LA',
    name: "Lao People's Democratic Republic",
    flag: {
      png: 'https://flagcdn.com/w320/la.png',
      svg: 'https://flagcdn.com/la.svg',
    },
    languages: [
      {
        code: 'lao',
        name: 'Lao',
        locale: 'lo-LA',
      },
    ],
    currencies: [
      {
        code: 'LAK',
        name: 'Lao kip',
        symbol: '₭',
      },
    ],
  },
  {
    code: 'TW',
    name: 'Taiwan, Province of China',
    flag: {
      png: 'https://flagcdn.com/w320/tw.png',
      svg: 'https://flagcdn.com/tw.svg',
    },
    languages: [
      {
        code: 'zho',
        name: 'Chinese',
        locale: 'zh-TW',
      },
    ],
    currencies: [
      {
        code: 'TWD',
        name: 'New Taiwan dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'CG',
    name: 'Republic of the Congo',
    flag: {
      png: 'https://flagcdn.com/w320/cg.png',
      svg: 'https://flagcdn.com/cg.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-CG',
      },
      {
        code: 'kon',
        name: 'Kikongo',
        locale: 'kg-CG',
      },
      {
        code: 'lin',
        name: 'Lingala',
        locale: 'ln-CG',
      },
    ],
    currencies: [
      {
        code: 'XAF',
        name: 'Central African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'CZ',
    name: 'Czechia',
    flag: {
      png: 'https://flagcdn.com/w320/cz.png',
      svg: 'https://flagcdn.com/cz.svg',
    },
    languages: [
      {
        code: 'ces',
        name: 'Czech',
        locale: 'cs-CZ',
      },
      {
        code: 'slk',
        name: 'Slovak',
        locale: 'sk-CZ',
      },
    ],
    currencies: [
      {
        code: 'CZK',
        name: 'Czech koruna',
        symbol: 'Kč',
      },
    ],
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    flag: {
      png: 'https://flagcdn.com/w320/gb.png',
      svg: 'https://flagcdn.com/gb.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-GB',
      },
    ],
    currencies: [
      {
        code: 'GBP',
        name: 'British pound',
        symbol: '£',
      },
    ],
  },
  {
    code: 'NE',
    name: 'Niger',
    flag: {
      png: 'https://flagcdn.com/w320/ne.png',
      svg: 'https://flagcdn.com/ne.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-NE',
      },
    ],
    currencies: [
      {
        code: 'XOF',
        name: 'West African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'CD',
    name: 'Democratic Republic of the Congo',
    flag: {
      png: 'https://flagcdn.com/w320/cd.png',
      svg: 'https://flagcdn.com/cd.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-CD',
      },
      {
        code: 'kon',
        name: 'Kikongo',
        locale: 'kg-CD',
      },
      {
        code: 'lin',
        name: 'Lingala',
        locale: 'ln-CD',
      },
      {
        code: 'lua',
        name: 'Tshiluba',
        locale: 'lua-CD',
      },
      {
        code: 'swa',
        name: 'Swahili',
        locale: 'sw-CD',
      },
    ],
    currencies: [
      {
        code: 'CDF',
        name: 'Congolese franc',
        symbol: 'FC',
      },
    ],
  },
  {
    code: 'BS',
    name: 'Commonwealth of The Bahamas',
    flag: {
      png: 'https://flagcdn.com/w320/bs.png',
      svg: 'https://flagcdn.com/bs.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-BS',
      },
    ],
    currencies: [
      {
        code: 'BSD',
        name: 'Bahamian dollar',
        symbol: '$',
      },
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
    flag: {
      png: 'https://flagcdn.com/w320/cc.png',
      svg: 'https://flagcdn.com/cc.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-CC',
      },
    ],
    currencies: [
      {
        code: 'AUD',
        name: 'Australian dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'CF',
    name: 'Central African Republic',
    flag: {
      png: 'https://flagcdn.com/w320/cf.png',
      svg: 'https://flagcdn.com/cf.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-CF',
      },
      {
        code: 'sag',
        name: 'Sango',
        locale: 'sg-CF',
      },
    ],
    currencies: [
      {
        code: 'XAF',
        name: 'Central African CFA franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'CK',
    name: 'Cook Islands',
    flag: {
      png: 'https://flagcdn.com/w320/ck.png',
      svg: 'https://flagcdn.com/ck.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-CK',
      },
      {
        code: 'rar',
        name: 'Cook Islands Māori',
        locale: 'rar-CK',
      },
    ],
    currencies: [
      {
        code: 'CKD',
        name: 'Cook Islands dollar',
        symbol: '$',
      },
      {
        code: 'NZD',
        name: 'New Zealand dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'FK',
    name: 'Falkland Islands',
    flag: {
      png: 'https://flagcdn.com/w320/fk.png',
      svg: 'https://flagcdn.com/fk.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-FK',
      },
    ],
    currencies: [
      {
        code: 'FKP',
        name: 'Falkland Islands pound',
        symbol: '£',
      },
    ],
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
    flag: {
      png: 'https://flagcdn.com/w320/fo.png',
      svg: 'https://flagcdn.com/fo.svg',
    },
    languages: [
      {
        code: 'dan',
        name: 'Danish',
        locale: 'da-FO',
      },
      {
        code: 'fao',
        name: 'Faroese',
        locale: 'fo-FO',
      },
    ],
    currencies: [
      {
        code: 'DKK',
        name: 'Danish krone',
        symbol: 'kr',
      },
      {
        code: 'FOK',
        name: 'Faroese króna',
        symbol: 'kr',
      },
    ],
  },
  {
    code: 'HM',
    name: 'Territory of Heard Island and McDonald Islands',
    flag: {
      png: 'https://flagcdn.com/w320/hm.png',
      svg: 'https://flagcdn.com/hm.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-HM',
      },
    ],
    currencies: [
      {
        code: 'AUD',
        name: 'Australian dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
    flag: {
      png: 'https://flagcdn.com/w320/io.png',
      svg: 'https://flagcdn.com/io.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-IO',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'KM',
    name: 'Comoros',
    flag: {
      png: 'https://flagcdn.com/w320/km.png',
      svg: 'https://flagcdn.com/km.svg',
    },
    languages: [
      {
        code: 'ara',
        name: 'Arabic',
        locale: 'ar-KM',
      },
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-KM',
      },
      {
        code: 'zdj',
        name: 'Comorian',
        locale: 'zdj-KM',
      },
    ],
    currencies: [
      {
        code: 'KMF',
        name: 'Comorian franc',
        symbol: 'Fr',
      },
    ],
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
    flag: {
      png: 'https://flagcdn.com/w320/ky.png',
      svg: 'https://flagcdn.com/ky.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-KY',
      },
    ],
    currencies: [
      {
        code: 'KYD',
        name: 'Cayman Islands dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'MH',
    name: 'Republic of the Marshall Islands',
    flag: {
      png: 'https://flagcdn.com/w320/mh.png',
      svg: 'https://flagcdn.com/mh.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-MH',
      },
      {
        code: 'mah',
        name: 'Marshallese',
        locale: 'mh-MH',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'MP',
    name: 'Commonwealth of the Northern Mariana Islands',
    flag: {
      png: 'https://flagcdn.com/w320/mp.png',
      svg: 'https://flagcdn.com/mp.svg',
    },
    languages: [
      {
        code: 'cal',
        name: 'Carolinian',
        locale: 'cal-MP',
      },
      {
        code: 'cha',
        name: 'Chamorro',
        locale: 'ch-MP',
      },
      {
        code: 'eng',
        name: 'English',
        locale: 'en-MP',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
    flag: {
      png: 'https://flagcdn.com/w320/tc.png',
      svg: 'https://flagcdn.com/tc.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-TC',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'TF',
    name: 'French Southern and Antarctic Lands',
    flag: {
      png: 'https://flagcdn.com/w320/tf.png',
      svg: 'https://flagcdn.com/tf.svg',
    },
    languages: [
      {
        code: 'fra',
        name: 'French',
        locale: 'fr-TF',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'UM',
    name: 'United States Minor Outlying Islands',
    flag: {
      png: 'https://flagcdn.com/w320/um.png',
      svg: 'https://flagcdn.com/um.svg',
    },
    languages: [
      {
        code: 'eng',
        name: 'English',
        locale: 'en-UM',
      },
    ],
    currencies: [
      {
        code: 'USD',
        name: 'United States dollar',
        symbol: '$',
      },
    ],
  },
  {
    code: 'VA',
    name: 'Holy See',
    flag: {
      png: 'https://flagcdn.com/w320/va.png',
      svg: 'https://flagcdn.com/va.svg',
    },
    languages: [
      {
        code: 'ita',
        name: 'Italian',
        locale: 'it-VA',
      },
      {
        code: 'lat',
        name: 'Latin',
        locale: 'la-VA',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'XK',
    name: 'Republic of Kosovo',
    flag: {
      png: 'https://flagcdn.com/w320/xk.png',
      svg: 'https://flagcdn.com/xk.svg',
    },
    languages: [
      {
        code: 'sqi',
        name: 'Albanian',
        locale: 'sq-XK',
      },
      {
        code: 'srp',
        name: 'Serbian',
        locale: 'sr-XK',
      },
    ],
    currencies: [
      {
        code: 'EUR',
        name: 'Euro',
        symbol: '€',
      },
    ],
  },
  {
    code: 'AN',
    name: 'Netherlands Antilles',
    flag: {
      png: undefined,
      svg: NetherlandsAntillesFlag,
    },
    currencies: [
      {
        code: 'ANG',
        name: 'Netherlands Antillean guilder',
        symbol: 'ƒ',
      },
    ],
    languages: [
      {
        code: 'nld',
        name: 'Dutch',
        locale: 'nl-AN',
      },
    ],
  },
];
