interface IDownloadCsvParams {
  fileName: string;
  headers: string[];
  data: unknown[];
}

const downloadCsv = ({ fileName, data, headers }: IDownloadCsvParams): void => {
  const csvData = `\uFEFF${[headers, ...data].join('\n')}`;

  const blob = new Blob([csvData], { type: 'text/csv; charset=utf-8' });
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export default downloadCsv;
