import { IEnvProps } from 'interfaces/IEnv';
import { createContext, FC, ReactNode, useEffect } from 'react';

const store: IEnvProps = {
  beesVendoCountries: '',
  europeClusterCountries: '',
  multivendorCountries: '',
  segmentKey: '',
};

interface IEnvProviderProps {
  env: IEnvProps;
  children: ReactNode;
}

export const EnvContext = createContext(store);

export const EnvProvider: FC<IEnvProviderProps> = ({ children, env }) => {
  useEffect(() => {
    if (env.europeClusterCountries) {
      localStorage.setItem('europeClusterCountries', env.europeClusterCountries);
    } else {
      localStorage.removeItem('europeClusterCountries');
    }
  }, [env.europeClusterCountries]);

  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
};
