import formatNumberByLocale from './formatNumberByLocale';

interface IProps {
  value?: number | null;
  locale?: string;
  symbol?: string;
}

const formattedCurrencyByLocale = ({ value, locale, symbol = '' }: IProps): string => {
  if (typeof value === 'number' && locale) {
    const formattedValue = formatNumberByLocale({
      value,
      locale,
      options: { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 },
    });

    return `${symbol}${formattedValue}`;
  }
  return '';
};

export default formattedCurrencyByLocale;
