import {
  SET_ACCOUNT_CARD_BOX,
  SET_BEES_FORCE_CARD_BOX,
  SET_BEES_VENDO_CARD_BOX,
  SET_CUSTOMER_CARD_BOX,
  SET_STORE_CARD_BOX,
  SET_VENDOR_CARD_BOX,
} from 'redux/actionTypes';
import { CardBoxStoreInterface, ICardBoxReducerAction } from '../interfaces';

const initialState: CardBoxStoreInterface = {
  account: undefined,
  vendor: undefined,
  beesVendo: undefined,
  store: undefined,
  customer: undefined,
  beesForce: undefined,
};

export default (state = initialState, action: ICardBoxReducerAction): CardBoxStoreInterface => {
  switch (action.type) {
    case SET_ACCOUNT_CARD_BOX: {
      const { account } = action.payload;
      return {
        ...state,
        account,
      };
    }
    case SET_VENDOR_CARD_BOX: {
      const { vendor } = action.payload;
      return {
        ...state,
        vendor,
      };
    }
    case SET_BEES_VENDO_CARD_BOX: {
      const { beesVendo } = action.payload;
      return {
        ...state,
        beesVendo,
      };
    }
    case SET_STORE_CARD_BOX: {
      const { store } = action.payload;
      return {
        ...state,
        store,
      };
    }
    case SET_CUSTOMER_CARD_BOX: {
      const { customer } = action.payload;
      return {
        ...state,
        customer,
      };
    }
    case SET_BEES_FORCE_CARD_BOX: {
      const { beesForce } = action.payload;
      return {
        ...state,
        beesForce,
      };
    }

    default:
      return state;
  }
};
